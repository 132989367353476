<template>
    <div id="coordenates">
        <div class="file-content"><document documentTitle="Montaña - 2A"> </document></div>
        <div class="file-content"><document documentTitle="Generación - 5A"> </document></div>
        <div class="file-content"><document documentTitle="Casa - 7A"> </document></div>
        <div class="file-content"><document documentTitle="Aves - 6D - 4D"> </document></div>
        <div class="file-content"><document documentTitle="Bosque - 7E"> </document></div>

        <div class="file-content"><document documentTitle="Red Distribución - 5C"> </document></div>
        <div class="file-content"><document documentTitle="Red Transporte Pájaros - 5D"> </document></div>
        <div class="file-content"><document documentTitle="Red Transporte - 5E"> </document></div>

    </div>
</template>

<script>

    import Document from '@/components/document/document';

    export default {
        name: "coordenates",
        components: {
            Document,
        },
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #coordenates {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 800px;
        .file-content {
            width: 130px;
            flex-basis: 130px;
            margin-bottom: 25px;
            margin-right: 25px;
        }

    }
</style>