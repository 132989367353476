<template>
    <div class="directories">
        <div class="directory-box">
            <directory :directoryTitle = "$t('documento01')"
                       directoryImage = "folder.png"
                       :directoryModalTitle = "$t('generadorVanderGraff')"
            >
                <template slot="modal-content">
                    <van-de-graff-generator></van-de-graff-generator>
                </template>
            </directory>
        </div>
        <div class="directory-box">
            <directory :directoryTitle = "$t('calendar')"
                       directoryImage = "calendar.png"
                       :directoryModalTitle = "$t('calendar')"
            >
                <template slot="modal-content">
                    <p style="color: #fff">Sin fechas importantes...</p>
                </template>
            </directory>
        </div>
        <div class="directory-box">
            <directory :directoryTitle = "$t('transicionEnergetica')"
                       directoryImage = "electric-transmision.png"
                       :directoryModalTitle = "$t('transicionEnergetica')"
                       directoryBlocked
            >
                <template slot="modal-content">
                    <security-word-energetic-transition></security-word-energetic-transition>
                </template>
            </directory>
        </div>
        <div class="directory-box">
            <directory :directoryTitle = "$t('email')"
                       directoryImage = "mail.png"
                       :directoryModalTitle = "$t('email')"
                       :directoryBlocked = isBlockedDirectory(2)
            >
                <template slot="modal-content">
                    <security-word-mail></security-word-mail>
                </template>
            </directory>
        </div>
        <div class="directory-box">
            <directory :directoryTitle = "$t('sostenibilidad')"
                       directoryImage = "sostenibility.png"
                       :directoryModalTitle = "$t('sostenibilidad')"
                       :directoryBlocked = isBlockedDirectory(3)
            >
                <template slot="modal-content">
                    <security-word-sostenibility v-if="isBlockedDirectory(3)"></security-word-sostenibility>
                    <sostenibility-directory v-else></sostenibility-directory>
                </template>
            </directory>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    import Card from '@/components/cards/card.vue';
    import Directory from '@/components/directory/directory.vue';

    import VanDeGraffGenerator from './../games/van-de-graff-generator/van-de-graff-generator.vue';
    import SecurityWordSostenibility from './../games/security-word/security-word-sostenibility.vue';
    import SostenibilityDirectory from './../games/sostenibility-directory/sostenibility-directory';
    import SecurityWordMail from './../games/security-word/security-word-mail.vue';
    import ElectricDiscoveries from '@/components/info/electric-discoveries/electric-discoveries.vue';
    import SecurityWordEnergeticTransition from './../games/security-word/security-word-energetic-transition';

    export default {
        name: "card-desktop-right",
        components: {
            Card,
            Directory,
            VanDeGraffGenerator,
            SecurityWordSostenibility,
            SostenibilityDirectory,
            SecurityWordMail,
            ElectricDiscoveries,
            SecurityWordEnergeticTransition
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {
            isBlockedDirectory(idDirectory) {
                if(idDirectory === 2) return this.getCurrentState().phase.id <= 3;
                if(idDirectory === 3) return this.getCurrentState().phase.id <= 2;

            }
        }
    }
</script>