<template>
    <div id="draf">
        <div v-if="showSent" class="mail-right-side-element-content">
            <div id="from" class="mail-right-side-mail">
                {{ $t('deEmail') }} Dr. Ohm
            </div>
            <div id="delete" class="icon-mail">
                <img src="./../assets/delete.png" alt="">
            </div>
            <div class="mail-right-side-mail">
                {{ $t('paraEmail') }} jefe.de.operaciones@cecoel.com
            </div>
            <div class="mail-right-side-mail">
                {{ $t('asuntoEmail') }} <span>{{ $t('sector2EmailEnviada') }}</span>
            </div>
            <div class="mail-right-side-content">
                <p v-html="$t('sector2EmailEnviadaContenido')"></p>
            </div>
        </div>
        <div v-else class="mail-right-side-element-no-message">
            <p>{{ $t('noMessagesEnviados') }}</p>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name: "sent",
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        data() {
            return {
                showSent: false
            }
        },
        created() {
            this.checkPhaseStatus();
        },
        methods: {
            checkPhaseStatus: function () {
                let state = this.getCurrentState();
                this.showSent = state.phase.id > 3;
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_mail.scss";

</style>