<template>
    <div id="screensave">

        <img id="dr-volta" src="./assets/dra-volta.jpg" alt="">

        <img class="glifo" id="glifo" src="./assets/glifo.png" alt="">
        <img class="glifo" id="glifo2" src="./assets/glifo.png" alt="">

        <img class="glifo" id="right-bottom" src="./assets/right-bottom-glifo.png" alt="">
        <img class="glifo" id="left-bottom" src="./assets/left-bottom-glifo.png" alt="">
        <img class="glifo" id="right-middle" src="./assets/right-middle-glifo.png" alt="">
        <img class="glifo" id="top" src="./assets/top-glifo.png" alt="">

        <div v-if="!this.wrongQr" class="text-screensave">
            <img src="./assets/electra-avatar.png" alt="">
            <h1>USA TU CÓDIGO QR <br /> PARA ACTIVAR ESTE SECTOR</h1>
        </div>
        <div v-else class="text-screensave wrong">
            <img src="./assets/electra-avatar-wrong.png" alt="">
            <h1>TU CÓDIGO QR <br /> NO ES VÁLIDO</h1>
        </div>
    </div>
</template>

<script>
    export default {
        name: "screensave",
        props: { wrongQr: Boolean },
        data() {
            return {
                screensaveEnable: true,
                currentState:  {}
            }
        },
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    
    #screensave {
        width: 100vw;
        height: 100vh;
        background-color: #00192d;
        display: flex;
        justify-content: center;
        align-items: center;
        .text-screensave {
            text-align: center;
            img {
                width: 95px;
                height: 95px;
                border-radius: 50%;
                animation: voice 1.5s infinite linear;
            }
            h1 {
                font-size: 45px;
                line-height: 50px;
                color: #fff;
            }
            &.wrong {
                h1 {
                    color: $red-color;
                }
                img {
                    animation: voiceError 1.5s infinite linear;
                }
            }
        }
        #dr-volta {
            position: absolute;
            top: 12%;
            left: -15%;
            width: 65%;
        }
        .glifo {
            position: absolute;
            &#left-bottom {
                left: 0;
                bottom: 0;
            }
            &#right-bottom {
                right: 0;
                bottom: 0;
            }
            &#right-middle {
                top: 40%;
                right: 0;
            }
            &#top {
                top: 0;
                left: 10%;
            }
            &#glifo {
                top: -80px;
                left: 60%;
                opacity: 0.3;
                transform: rotate(15deg);
            }
            &#glifo2 {
                width: 300px;
                bottom: -100px;
                left: 40%;
                opacity: 0.3;
                transform: rotate(15deg);
            }
        }
    }

    @keyframes voice {
        0% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.1);
            box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.1);
        }
        50% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,1);
            box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.9);
        }
        50% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,1);
            box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.9);
        }
        100% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.01);
            box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.01);
        }
    }

    @keyframes voiceError {
        0% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($red-color,0.1);
            box-shadow: 0px 1px 30px -4px rgba($red-color,0.1);
        }
        50% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($red-color,1);
            box-shadow: 0px 1px 30px -4px rgba($red-color,0.9);
        }
        50% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($red-color,1);
            box-shadow: 0px 1px 30px -4px rgba($red-color,0.9);
        }
        100% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($red-color,0.01);
            box-shadow: 0px 1px 30px -4px rgba($red-color,0.01);
        }
    }



</style>
