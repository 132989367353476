<template>
    <div id="cecoel-app-curves">
        <p v-html="$t('sector2DemandasPotencia')"></p>
        <div class="curves-content">
            <div class="curve">
                <span>{{ $t('sector2Sabado') }} 5</span>
                <img src="./assets/curves.png" alt="">
            </div>
            <div class="curve">
                <span>{{ $t('sector2Domingo') }} 9</span>
                <img src="./assets/curves.png" alt="">
            </div>
            <div class="curve">
                <span>{{ $t('sector2Lunes') }} 10</span>
                <img src="./assets/curves.png" alt="">
            </div>
            <div class="curve">
                <span>{{ $t('sector2Martes') }} 11</span>
                <img src="./assets/curves.png" alt="">
            </div>
            <div class="curve">
                <span>{{ $t('sector2Miercoles') }} 12</span>
                <img src="./assets/curves.png" alt="">
            </div>
            <div class="curve">
                <span>{{ $t('sector2Jueves') }} 13</span>
                <img src="./assets/curves-red.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "cecoel-app-curves"
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #cecoel-app-curves {
        width: 900px;
        p {
            font-size: 20px;
            line-height: 22px;
            margin: 0 0 5px 0;
            color: #fff;
            span {
                color: $primary-blue-color;
            }
        }
        .curves-content {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .curve {
                position: relative;
                flex-basis: calc(33% - 8px);
                width: calc(33% - 8px);
                margin-bottom: 10px;
                border: 2px solid transparent;
                img {
                    width: 100%;
                    height: auto;
                }
                span {
                    position: relative;
                    top: 40px;
                    left: 20px;
                    color: $primary-blue-color;
                    font-weight: bold;
                }
            }
        }

    }
</style>