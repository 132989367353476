<template>
    <div id="sorian-proyect">
        <p class="text-sorian-project">{{ $t('sector4ProyectoSorianTitle') }}</p>
        <div class="directories">
            <div class="directory-box">
                <directory :directoryTitle = "$t('sector4ProyectoSorianSistema')"
                           directoryImage = "electric-system.png"
                           :directoryModalTitle = "$t('sector4ProyectoSorianSistema')"
                >
                    <template slot="modal-content">
                        <electric-system></electric-system>
                    </template>
                </directory>
            </div>
            <div class="directory-box">
                <directory :directoryTitle = "$t('sector4ProyectoSorianCoordenadas')"
                           directoryImage = "folder.png"
                           :directoryModalTitle = "$t('sector4ProyectoSorianCoordenadas')"
                >
                    <template slot="modal-content">
                        <coordinates></coordinates>
                    </template>
                </directory>
            </div>
            <div class="directory-box">
                <directory :directoryTitle = "$t('sector4ProyectoSorianNotas')"
                           directoryImage = "folder.png"
                           :directoryModalTitle = "$t('sector4ProyectoSorianNotas')"
                >
                    <template slot="modal-content">
                        <p style="color: #fff">{{ $t('sector4ProyectoSorianNotasMessage') }}</p>
                    </template>
                </directory>
            </div>
        </div>
    </div>
</template>

<script>

    import Card from '@/components/cards/card.vue';
    import Directory from '@/components/directory/directory.vue';

    import ElectricSystem from './electric-system/electric-system';
    import Coordinates from './coordenates/coordenates';

    export default {
        name: "sorian-project",
        components: {
            Card,
            Directory,
            ElectricSystem,
            Coordinates
        },
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #sorian-proyect {
        width: 800px;
        min-width: 800px;
        .text-sorian-project {
            color: #fff;
            margin-bottom: 40px;
        }
        .directories {
            .directory-box {
                flex-basis: calc(33% - 30px) !important;
                width: calc(33% - 30px) !important;
            }
        }
    }
</style>