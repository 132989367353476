<template>
    <div id="step-two">
        <div class="text-box-top">
            <p v-if="!stepSolved">{{ $t('sector3SistemaVoltaStep1TwoTitle') }}</p>
            <div class="solved-text" v-else>
                <p v-html="$t('sector3SistemaVoltaStep1TwoCorrect')"></p>
            </div>
        </div>
        <div class="boxes">
            <div class="box" :id="box.letter" @click="setBox(box.value,box.letter)" v-bind:style="{ order: (key <= 3) ? 1 : 3 }" v-for="(box,key) in boxes">
                <span class="letter">{{ box.letter }}</span>
                <p class="name">{{ box.name }}</p>
                <p class="subname">{{ box.subname }}</p>
                <img :src="require(`${box.image}`)" alt="">
            </div>
            <div v-bind:class="{'line-solution' : true, 'incorrect' : (total > 1850), 'correct' : stepSolved}">
                <div class="box-line">
                    <span>{{ total }}</span>
                </div>
                <div class="line">
                    <span v-bind:style="{width: checkWidthBar}"></span>
                </div>
                <div class="box-line">
                    <span v-if="stepSolved">1850</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: "step-two",
        data() {
            return {
                total: 0,
                stepSolved: false,
                boxes:  [
                    {letter: 'A', image: './assets/steptwo/A.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoA'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoAName'), value: 300},
                    {letter: 'B', image: './assets/steptwo/B.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoB'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoBName'), value: 150},
                    {letter: 'C', image: './assets/steptwo/C.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoC'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoCName'), value: 800},
                    {letter: 'D', image: './assets/steptwo/D.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoD'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoDName'), value: 450},
                    {letter: 'E', image: './assets/steptwo/E.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoE'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoEName'), value: 120},
                    {letter: 'F', image: './assets/steptwo/F.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoF'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoFName'), value: 200},
                    {letter: 'G', image: './assets/steptwo/G.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoG'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoGName'), value: 450},
                    {letter: 'H', image: './assets/steptwo/H.png', name: this.$i18n.t('sector3SistemaVoltaStep1TwoH'), subname: this.$i18n.t('sector3SistemaVoltaStep1TwoHName'), value: 300},
                ]
            }
        },
        computed: {
            ...mapGetters(['getCurrentState']),
            checkWidthBar: function () {
                if(this.total >= 1850 ) return '100%';
                else return (this.total/1850)*100+'%';
            }
        },
        created() {
            this.checkPhaseStatus();
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus : function() {
                let state = this.getCurrentState();
                if(state.phase.id > 2) {
                    this.total = 1850;
                    this.stepSolved = true;
                }
            },
            setBox: function (total, id) {

                let element = document.getElementById(id);
                if(element.classList.contains('active')) {
                    this.total -= total;
                }
                else {
                    this.total += total;
                }

                element.classList.toggle('active');

                this.stepSolved = this.total === 1850;
                if(this.stepSolved) {
                    this.updateCurrentPhase({id: 3, complete: false, current: true});
                    this.updateUserdata();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #step-two {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        width: 1000px;
        min-width: 1000px;
        padding: 40px;
        .text-box-top {
            flex-basis: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 50px;
            p {
                color: #fff;
            }
            .solved-text {
                width: 100%;
                p {
                    text-align: center;
                    margin: 0;
                    &:first-child {
                        color: $green-color;
                        font-size: 30px;
                        line-height: 35px;
                        font-weight: bold;
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .boxes {
            flex-basis: 65%;
            width: 65%;
            height: 500px;
            display: flex;
            flex-wrap: wrap;
            .box {
                position: relative;
                flex-basis: 110px;
                width: 110px;
                margin-right: 25px;
                height: 120px;
                padding: 10px;
                border: 2px solid rgba($primary-blue-color,0.5);
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                border-radius: 10px;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                .letter {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    font-size: 45px;
                    line-height: 48px;
                    color: #6e76a0;
                }
                img {
                    position: absolute;
                    top: -18px;
                    right: -20px;
                }
                p {
                    margin: 0;
                    &.name {
                        font-size: 18px;
                        line-height: 20px;
                        font-weight: bold;
                        color: $primary-blue-color;
                        margin-bottom: 5px;
                    }
                    &.subname {
                        font-size: 14px;
                        line-height: 16px;
                        min-height: 35px;
                        color: #fff;
                    }
                }
                &:hover,
                &.active {
                    cursor: pointer;
                    border-color: $primary-blue-color;
                    -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
                    box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
                }
            }
            .line-solution {
                order: 2;
                flex-basis: calc(100% - 50px);
                width: calc(100% - 50px);
                display: flex;
                align-items: center;
                margin: -20px 0 20px 0;
                .box-line {
                    border: 1px solid $primary-blue-color;
                    width: 120px;
                    height: 60px;
                    border-radius: 5px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    span {
                        color: $primary-blue-color;
                        font-size: 25px;
                        line-height: 27px;
                    }
                }
                .line {
                    flex-basis: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    height: 10px;
                    border-top: 1px solid $primary-blue-color;
                    border-bottom: 1px solid $primary-blue-color;
                    span {
                        display: inline-block;
                        height: 10px;
                        @include gradient(90deg,$list-green);
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                    }
                }
                &.incorrect {
                    .box-line {
                        border-color: $red-color;
                        background-color: rgba($red-color,0.5);
                        span {
                            color: #fff;
                        }
                    }
                    .line {
                        border-color: $red-color;
                        span {
                            @include gradient(90deg,$list-red);
                        }
                    }
                }
                &.correct {
                    .box-line {
                        border-color: $green-color;
                        background-color: rgba($green-color,0.5);
                        span {
                            color: #fff;
                        }
                    }
                    .line {
                        border-color: $green-color;
                    }
                }

            }
        }
    }
</style>