<template>
    <div id="step-one" v-bind:class="{'resolved' : resolved}">
        <div class="text-top-box">
            <p v-if="!resolved">{{ $t('sector3SistemaVoltaStep1OneTitle') }}</p>
            <p v-else class="solved-text">{{ $t('sector3SistemaVoltaStep1OneCorrect') }}</p>
        </div>
        <img src="./assets/stepone/base-circuit.png" alt="">
        <draggable group="electricGeneration" :options="{disabled: resolved}" :list="results">
            <div :id="result.key + '-drop'" class="drop-box" v-for="result in results">
                <span>{{ result.name }}</span>
                <img v-if="result.url" :src="require(`${result.url}`)" alt="">
            </div>
        </draggable>
        <div v-if="!resolved" class="footer-step-one">
            <draggable group="electricGeneration" class="carousel-elements" v-model="images" @start="isDragging=false" @end="isDragging=false" :move="startDrag" >
                <div class="carousel-box"
                     :id="image.key"
                     v-for="(image,index) in images">
                    <img :src="require(`${image.url}`)" alt="">
                </div>
            </draggable>
            <div class="active-button-content">
                <active-button v-if="powerOn" v-on:pass="checkPuzzle()" enabled></active-button>
                <active-button v-else></active-button>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    import draggable from 'vuedraggable'
    import ActiveButton from './activate-button';

    export default {
        name: "step-one",
        data() {
            return {
                images: [
                    {url: './assets/stepone/generacion.png', key: 'generation-1'},
                    {url: './assets/stepone/generacion-2.png', key: 'generation-2'},
                    {url: './assets/stepone/generacion-3.png', key: 'generation-3'},
                    {url: './assets/stepone/transporte.png', key: 'transport'},
                    {url: './assets/stepone/distribucion.png', key: 'distribution'},
                    {url: './assets/stepone/consumo.png', key: 'consume'},
                    {url: './assets/stepone/domestico.png', key: 'domestic'},
                    {url: './assets/stepone/industrial.png', key: 'industrial'},
                ],
                results: [
                    {key: 'generation-1', name: this.$i18n.t('sector3SistemaVoltaStep1Generacion'), url: null},
                    {key: 'transport', name: this.$i18n.t('sector3SistemaVoltaStep1Transporte'), url: null},
                    {key: 'distribution', name: this.$i18n.t('sector3SistemaVoltaStep1Distribucion'), url: null},
                    {key: 'consume', name: this.$i18n.t('sector3SistemaVoltaStep1Consume'), url: null},
                    {key: 'domestic', name: this.$i18n.t('sector3SistemaVoltaStep1Domestico'), url: null},
                    {key: 'industrial', name: this.$i18n.t('sector3SistemaVoltaStep1Industrial'), url: null},
                ],
                resolved: false,
                powerOn: false
            }
        },
        components: {
            ActiveButton,
            draggable
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        created() {
            this.checkPhaseStatus();
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus : function() {
                let state = this.getCurrentState();
                if(state.phase.id > 1) {
                    this.resolved = true;
                    this.solvedPuzzle();
                }
            },
            solvedPuzzle : function(){
                this.results = [
                    {key: 'generation', name: this.$i18n.t('sector3SistemaVoltaStep1Generacion'), url: './assets/stepone/generacion.png'},
                    {key: 'transport', name: this.$i18n.t('sector3SistemaVoltaStep1Transporte'), url: './assets/stepone/transporte.png'},
                    {key: 'distribution', name: this.$i18n.t('sector3SistemaVoltaStep1Distribucion'), url: './assets/stepone/distribucion.png'},
                    {key: 'consume', name: this.$i18n.t('sector3SistemaVoltaStep1Consume'), url: './assets/stepone/consumo.png'},
                    {key: 'domestic', name: this.$i18n.t('sector3SistemaVoltaStep1Domestico'), url: './assets/stepone/domestico.png'},
                    {key: 'industrial', name: this.$i18n.t('sector3SistemaVoltaStep1Industrial'), url: './assets/stepone/industrial.png'},
                ]
            },
            startDrag : function ({ relatedContext, draggedContext }) {

                let droppedElement = relatedContext.element;
                let draggedElement = draggedContext.element;

                if(draggedElement.key == droppedElement.key) {


                    let dragKey = draggedElement.key;
                    let dragUrl = draggedElement.url;

                    this.setDragPosition(dragKey,dragUrl,droppedElement);
                }
                return false;

            },
            setDragPosition(dropKey,dragUrl,result) {

                this.images = this.images.filter(image => image.key != dropKey);
                result.url = dragUrl;

                if(this.images.length === 2)  this.powerOn = true;
            },
            checkPuzzle() {
                this.resolved = true;
                if(this.powerOn) {
                    this.updateCurrentPhase({id: 2, complete: false, current: true});
                    this.updateUserdata();
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #step-one {
        margin: 30px auto 50px auto;
        width: 1000px;
        min-width: 1000px;
        height: auto;
        position: relative;
        .text-top-box {
            height: 90px;
            display: flex;
            align-items: center;
            p {
                color: #fff;
                margin: 0 0 30px 0;
                &.solved-text {
                    width: 100%;
                    text-align: center;
                    color: $green-color;
                    font-size: 30px;
                    line-height: 35px;
                    font-weight: bold;
                }
            }
        }
        img {
            width: 100%;
            height: auto;
        }
        .drop-box {
            position: absolute;
            z-index: 4;
            background-color: #06267e;
            border: 1px solid $primary-blue-color;
            border-radius: 10px;
            padding: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            span {
                display: block;
                text-transform: uppercase;
                text-align: center;
                font-size: 14px;
                margin-bottom: 15px;
                color: $primary-blue-color;
            }
            img {
                height: 60%;
                width: auto;
            }
            &#generation-1-drop, &#generation-drop{
                width: 85px;
                height: 70px;
                top: 227px;
                left: 6px;
            }
            &#transport-drop {
                width: 101px;
                height: 118px;
                top: 193px;
                left: 193px;
            }
            &#distribution-drop {
                width: 101px;
                height: 103px;
                top: 203px;
                left: 416px;
            }
            &#consume-drop {
                width: 60px;
                height: 84px;
                top: 216px;
                left: 658px;
            }
            &#domestic-drop {
                width: 165px;
                height: 103px;
                top: 92px;
                left: 800px;
            }
            &#industrial-drop {
                width: 165px;
                height: 103px;
                top: 349px;
                left: 800px;
            }
        }
        .footer-step-one {
            height: 130px;
            margin-top: 50px;
            width: 100%;
            display: flex;
            .carousel-elements {
                width: 700px;
                border-radius: 10px;
                border: 1px solid rgba($primary-blue-color, 0.5);
                display: flex;
                align-items: center;
                overflow-y: hidden;
                overflow-x: auto;
                .carousel-box {
                    z-index: 3;
                    width: 90px;
                    min-width: 90px;
                    height: 90px;
                    min-height: 90px;
                    border-radius: 10px;
                    border: 1px solid rgba($primary-blue-color, 0.5);
                    background-color: #06267e;
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 30%;
                        height: auto;
                    }
                    &:first-child {
                        margin-left: 10px;
                    }
                }
            }
        }
        .active-button-content {
            position: absolute;
            right: 0;
            bottom: 0;
        }
        &.resolved {
            .drop-box {
                border: 2px solid $primary-blue-color;
                -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
                box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.71);
            }
        }
    }
</style>