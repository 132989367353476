<template>
    <div id="sostenibility-directory">
        <div class="directory-box">
            <directory :directoryTitle = "$t('diagramas')"
                       directoryImage = "folder.png"
                       :directoryModalTitle = "$t('diagramas2')"
            >
                <template slot="modal-content">
                   <diagrams-folder></diagrams-folder>
                </template>
            </directory>
        </div>
        <div class="directory-box">
            <directory :directoryTitle = "$t('morseMessage')"
                       directoryImage = "folder.png"
                       :directoryModalTitle = "$t('morseMessage')"
            >
                <template slot="modal-content">
                    <morse-code></morse-code>
                </template>
            </directory>
        </div>
        <div class="directory-box">
            <directory :directoryTitle = "$t('hitosElectricidad')"
                       directoryImage = "electric-history.png"
                       :directoryModalTitle = "$t('hitosElectricidad')"
            >
                <template slot="modal-content">
                    <electric-discoveries></electric-discoveries>
                </template>
            </directory>
        </div>
    </div>
</template>

<script>

    import Directory from '@/components/directory/directory.vue';
    import ElectricDiscoveries from '@/components/info/electric-discoveries/electric-discoveries.vue';

    import MorseCode from './../morse-code/morse-code.vue';
    import DiagramsFolder from './mini-components/diagrams-folder';

    export default {
        name: "sostenibility-directory",
        components: {
            Directory,
            ElectricDiscoveries,
            MorseCode,
            DiagramsFolder
        },
    }
</script>

<style lang="scss" scoped>
    #sostenibility-directory {
        width: 900px;
        display: flex;
        flex-wrap: wrap;
    }
</style>