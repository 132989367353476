<template>
    <div class="clue">
        <div class="clue-content clue-content-close" @click="show = !show" v-if="!show">
            <img :src="require(`./assets/clue.png`)" alt="">
            <p>{{ this.clueTitle }}</p>
        </div>
        <div class="clue-content clue-content-open" v-else>
            <p>{{ shortContent }} ...</p>
            <span @click="openCloseModal()">Ver más</span>
        </div>
        <draggable-modal v-on:close="openCloseModal()" class="clue-modal" v-if="this.open">
            <template slot="header">{{ this.clueTitle }}</template>
            <template slot="body">
                <div class="clue-modal-content">
                    <p>{{ this.clueContent }}</p>
                </div>
            </template>
        </draggable-modal>
    </div>
</template>

<script>

    import DraggableModal from '@/components/modals/draggable-modal';

    export default {
        name: "clue",
        props: {
            clueTitle : String,
            clueContent: String
        },
        components: {
            DraggableModal,
        },
        data() {
            return {
                show : false,
                open: false,
            }
        },
        computed: {
            shortContent: function () {
                return this.clueContent.slice(0,80);
            }
        },
        methods: {
            openCloseModal: function () {
                this.open = !this.open;
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    .clue {
        width: calc(100% - 20px);
        height: 100%;
        border-radius: 10px;
        padding: 10px;
        border: 1px solid $primary-purple-color;
        background-color: $primary-color;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        .clue-content {
            border-radius: 10px;
            padding: 15px;
            border: 1px solid $primary-purple-color;
            background-color: transparent;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            &.clue-content-close {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img {
                    width: 50%;
                    height: auto;
                }
                p {
                    color: #fff;
                    text-transform: uppercase;
                    text-align: center;
                }
            }
            &.clue-content-open {
                p {
                    color: #fff;
                    font-size: 14px;
                    line-height: 17px;
                }
                span {
                    color:  $primary-blue-color;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        &:hover {
            cursor: pointer;
            background-color: $secondary-color;
            border-color: $primary-blue-color;
            .clue-content {
                border-color: $primary-blue-color;
            }
        }
        .clue-modal {
            .clue-modal-content {
                padding: 20px;
                p {
                    color: #fff;
                }
            }
        }
    }
</style>