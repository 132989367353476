<template>
    <div id="cecoel-app-lights">
        <div class="left-part-app-lights">
            <img src="./assets/spain-map.png" alt="">
            <img src="./assets/equalizer.png" alt="">
        </div>
        <div class="right-part-app-lights">
            <img src="./assets/graphic.png" alt="">
            <div class="keywords-part">
                <div class="keywords-part-left">
                    <div class="cecoel-time-box cecoel-box">
                        <span>{{ countdown }}</span>
                    </div>
                    <div class="cecoel-colors-box cecoel-box">
                        <span id="red"></span>
                        <span id="yellow"></span>
                        <span id="green"></span>
                    </div>
                    <div class="cecoel-color-box-result cecoel-box">
                        <span>(</span>
                        <input @click="setFocus('red')" v-model="red" id="red-input" style="border: 1px solid #ae1525" type="text" autocomplete="off">
                        <span>x</span>
                        <input @click="setFocus('yellow')" v-model="yellow" id="yellow-input" style="border: 1px solid #e5ca1e" type="text" autocomplete="off">
                        <span>)+</span>
                        <input @click="setFocus('green')" v-model="green" id="green-input" style="border: 1px solid #18b35e" type="text" autocomplete="off">
                        <span>=</span>
                        <span class="result">{{ result }}</span>
                    </div>
                    <div class="cecoel-key-box-result cecoel-box">
                        <p v-if="solvedLights">{{ $t('sector2CecoelApp') }}</p>
                        {{ $t('sector2CecoelAppKey') }}
                        <div v-if="solvedLights">
                            <span>1</span><span>0</span><span>0</span>
                        </div>
                        <div v-else>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div class="keywords-part-right">
                    <div @click="setNumber(1)" class="number">1</div>
                    <div @click="setNumber(2)" class="number">2</div>
                    <div @click="setNumber(3)" class="number">3</div>
                    <div @click="setNumber(4)" class="number">4</div>
                    <div @click="setNumber(5)" class="number">5</div>
                    <div @click="setNumber(6)" class="number">6</div>
                    <div @click="setNumber(7)" class="number">7</div>
                    <div @click="setNumber(8)" class="number">8</div>
                    <div @click="setNumber(9)" class="number">9</div>
                    <div @click="setNumber(0)" class="number zero">0</div>
                    <div class="number">
                        <img src="./assets/enter.png" alt="">
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: "cecoel-app-lights",
        data() {
            return {
                countdown : '59:00',
                solvedLights: false,
                red: 0,
                redFocus: false,
                yellow: 0,
                yellowFocus: false,
                green: 0,
                greenFocus: false
            }
        },
        created() {
            this.checkPhaseStatus()
        },
        computed: {
            ...mapGetters(['getCurrentState']),
            result: function () {
                return (parseInt(this.red * this.yellow) + parseInt(this.green))
            }
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus: function () {
                let state = this.getCurrentState();
                this.solvedLights = state.phase.id > 1;
                if(this.solvedLights) {
                    this.red = 12;
                    this.yellow = 8;
                    this.green = 4;
                }
                else {
                    this.initTime(59,1);
                }
            },
            initTime: function(mm,ss) {
                let count = 1;
                let interval = setInterval(() => {
                    if(mm === 0 && ss === 0){
                        clearInterval(interval);
                    }
                    ss--;
                    if(ss === 0) {
                        ss = 59;
                        mm--;
                        if(mm === 0)
                        {
                            mm = 59;
                        }
                    }

                    this.closeLights();
                    if(count > 1) {
                        this.openLights(count);
                    }

                    if(mm.toString().length < 2) mm = "0"+mm;
                    if(ss.toString().length < 2) ss = "0"+ss;

                    this.countdown = mm + ":" +ss;
                    count++;
                },1000);
            },
            openLights: function(count) {

                let redElement = document.getElementById('red');
                let yellowElement = document.getElementById('yellow');
                let greenElement = document.getElementById('green');

                if(count % 12 === 0 && redElement) redElement.classList.add('active');
                if(count % 8 === 0 && yellowElement) yellowElement.classList.add('active');
                if(count % 4 === 0 && greenElement) greenElement.classList.add('active');
            },
            closeLights: function(){
                let redElement = document.getElementById('red');
                let yellowElement = document.getElementById('yellow');
                let greenElement = document.getElementById('green');

                if(redElement) redElement.classList.remove('active');
                if(yellowElement) yellowElement.classList.remove('active');
                if(greenElement) document.getElementById('green').classList.remove('active');
            },
            setFocus: function(name) {
                this.redFocus = false; this.yellowFocus = false; this.greenFocus = false;
                if(name === 'red') this.redFocus = true;
                else if(name === 'yellow') this.yellowFocus = true;
                else if(name === 'green') this.greenFocus = true;
            },
            setNumber: function(number) {
                let numberString = number.toString();
                if(this.redFocus) this.red += numberString;
                else if(this.yellowFocus) this.yellow += numberString;
                else if(this.greenFocus)this.green += numberString;

                this.checkStatus();
            },
            checkStatus: function () {
                if(
                    parseInt(this.red) === 12
                    && parseInt(this.yellow) === 8
                    && parseInt(this.green) === 4
                ) {
                    this.solvedLights = true;
                    this.updateCurrentPhase({id: 2, complete: false, current: true});
                    this.updateUserdata();
                }

            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #cecoel-app-lights {
        width: 900px;
        display: flex;
        padding: 40px;
        .left-part-app-lights {
            flex-basis: 37%;
            width: 37%;
            margin-right: 50px;
            img {
                width: 100%;
                &:first-child {
                    margin-bottom: 25px;
                }
            }
        }
        .right-part-app-lights {
            flex-basis: calc(63% - 50px);
            width: calc(63% - 50px);
            img {
                width: 100%;
            }
            .keywords-part {
                margin-top: 20px;
                display: flex;
                align-items: flex-start;
                padding: 20px;
                background-color: #070d44;
                border-radius: 15px;
                .keywords-part-left {
                    flex-basis: 65%;
                    width: 65%;
                    display: flex;
                    flex-wrap: wrap;
                    .cecoel-box {
                        padding: 15px;
                        border-radius: 10px;
                        border: 1px solid #323c84;
                        margin-bottom: 10px;
                        span {
                            color: #fff;
                            font-size: 20px;
                            line-height: 22px;
                            letter-spacing: 2px;
                            &.result {
                                font-size: 30px;
                                line-height: 33px;
                                margin-left: 20px;
                                letter-spacing: 2px;
                            }
                        }
                        &.cecoel-time-box {
                            margin-right: 10px;
                        }
                        &.cecoel-colors-box {
                            width: 56%;
                            flex-basis: 56%;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            span {
                                display: block;
                                flex-basis: calc(33% - 8px);
                                width: calc(33% - 8px);
                                height: 8px;
                                border-radius: 8px;
                                -webkit-transition: 0.3s;
                                transition: 0.3s;
                                &#red {
                                    border: 2px solid #ae1525;
                                    &.active {
                                        background-color: #ae1525;
                                    }

                                }
                                &#yellow {
                                    border: 2px solid #e5ca1e;
                                    &.active {
                                        background-color: #e5ca1e;
                                    }
                                }
                                &#green {
                                    border: 2px solid #18b35e;
                                    &.active {
                                        background-color: #18b35e;
                                    }
                                }
                            }
                        }
                        &.cecoel-color-box-result {
                            width: 100%;
                            flex-basis: 100%;
                            margin-bottom: 10px;
                            display: flex;
                            align-items: center;
                            input {
                                width: 30px;
                                height: 30px;
                                margin: 0 5px;
                                background-color: #070d44;
                                border-radius: 10px;
                                color: #fff;
                                text-align: center;
                                font-size: 18px;
                                line-height: 18px;
                            }
                        }
                        &.cecoel-key-box-result {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            color: #fff;
                            font-size: 18px;
                            border: 1px solid $green-color;
                            background-color: rgba($green-color,0.4);
                            div {
                                display: flex;
                                margin-left: 15px;
                                span {
                                    position: relative;
                                    display: block;
                                    width: 12px;
                                    height: 26px;
                                    padding-bottom: 5px;
                                    margin-right: 10px;
                                    &:after {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        bottom: 0;
                                        width: 100%;
                                        height: 2px;
                                        background-color: #fff;
                                    }
                                }
                            }

                            p {
                                flex-basis: 100%;
                                width: 100%;
                                font-size: 16px;
                            }
                        }
                    }
                }
                .keywords-part-right {
                    margin-left: 10px;
                    flex-basis: calc(35% - 10px);
                    width: calc(35% - 10px);
                    display: flex;
                    flex-wrap: wrap;
                    .number {
                        width: 45px;
                        height: 45px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 20px;
                        line-height: 20px;
                        color: #fff;
                        font-weight: bold;
                        margin: 0 5px 5px 0;
                        border-radius: 10px;
                        border: 1px solid #323c84;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        img {
                            width: 20px;
                            height: auto;
                        }
                        &.zero {
                            width: 97px;
                        }
                        &:hover {
                            background-color: #1d255b;
                            border-color: $primary-blue-color;
                        }
                    }
                }
            }
        }
    }
</style>