<template>
    <div id="complete-phases">
        <span>{{ $t('headerPhase') }} 0.{{ getCurrentState().phase.id }}<br /> {{ $t('headerPhaseComplete') }}</span>
        <div class="lines-divisor"></div>
        <div style="margin-left: 0" v-bind:class="{'phases' : true, 'current' : isCurrentPhase(1), 'complete' : isCompletePhase(1)}">
            <span>01</span>
        </div>
        <div v-if="this.getCurrentState().sector.id <= 4" class="phases" v-bind:class="{'phases' : true, 'current' : isCurrentPhase(2), 'complete' : isCompletePhase(2)}">
            <span>02</span>
        </div>
        <div v-if="this.getCurrentState().sector.id < 4" class="phases" v-bind:class="{'phases' : true, 'current' : isCurrentPhase(3), 'complete' : isCompletePhase(3)}">
            <span>03</span>
        </div>
        <div v-if="this.getCurrentState().sector.id < 4" class="phases" v-bind:class="{'phases' : true, 'current' : isCurrentPhase(4), 'complete' : isCompletePhase(4)}">
            <span>04</span>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    export default {
        name: "header-nav-complete-phases",
        data() {
            return {
                currentState:  {
                    id: 0, current: false, complete: false
                }
            }
        },
        computed: {
            ...mapGetters([
                'getCurrentState',
            ])
        },
        methods: {
            isCurrentPhase: function(idPhase) {
                let phase = this.getCurrentState().phase;
                return phase.id === idPhase && phase.current === true;
            },
            isCompletePhase: function(idPhase) {
                let phase = this.getCurrentState().phase;
                return phase.id > idPhase || (phase.id === 4 && phase.complete);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #complete-phases {
        display: flex;
        align-items: center;
        padding: 15px;
        border-radius: 7px;
        span {
            color: #fff;
            text-transform: uppercase;
        }
        .phases {
            margin-left: 15px;
            width: 50px;
            height: 50px;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primary-color;
            border: 1px solid #415997;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            span {
                color: #fff;
                opacity: 0.4;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                font-size: 25px;
                line-height: 30px;
            }
            &.complete {
                background-color: rgba($primary-blue-color, 0.7);
                span {
                    opacity: 1;
                    text-shadow: 0px 0px 6px rgb(255 255 255);
                }
            }
            &.current {
                border: 1px solid $primary-blue-color;
                span {
                    opacity: 1;
                }
            }
        }
    }
</style>