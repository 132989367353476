<template>
    <div id="paused-game">
        <div class="modal-intro-general">
            <p class="intro-text">¡{{ $t('hiHeader') }}, <span>{{ getUsername() }}</span>!
                {{ $t('stopGameText') }}
                <br /><br />
                {{ $t('stopGameTextTwo') }}</p>
            <div class="button-electric" @click="returnGame()">{{ $t('stopGameTextButton') }}</div>
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: "intro-sector",
        data() {
            return {
                interval : null
            }
        },
        computed: {
            ...mapGetters(['getUsername']),
        },
        created() {
            this.initTime(0,31);
        },
        methods: {
            ...mapMutations(['updatePausedGame','updateUserdata']),
            returnGame: function () {
                this.updatePausedGame({pausedGame: false});
                clearInterval(this.interval);
            },
            initTime(mm,ss) {
                this.interval = setInterval(() => {

                    if(mm ===-1){
                        this.updateUserdata();
                        clearInterval(this.interval);
                        location.reload();
                    }
                    ss--;
                    if(ss === 0) {
                        ss = 59;
                        mm--;
                        if(mm === 0)
                        {
                            mm = 59;
                        }
                    }
                },1000);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #paused-game {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('./../../assets/background.jpg') no-repeat center center;
        background-size: cover;
        text-align: center;
        .button-electric {
            display: inline-block;
            margin-top: 40px;
        }
        .modal-intro-general {
            width: 600px;
            height: auto;
            padding: 100px;
            background-color: rgba($primary-color,0.8);
            border-radius: 25px;
            p {
                font-weight: bold;
                text-align: center;
                font-size: 35px;
                line-height: 37px;
                color: #fff;
                span {
                    color: $primary-blue-color;
                }
            }
        }
    }
</style>