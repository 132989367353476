<template>
    <div id="mail-content">
        <div class="mail-card mail-left-side">
            <div @click="changeMailContent('entryBox')" v-bind:class="{'mail-left-side-element' : true, 'active' : this.currentMail.entryBox}">
                <img src="" alt="">
                <p>{{ $t('bandejaEntrada') }}</p>
                <span v-if="showEntry" class="number">01</span>
            </div>
            <div @click="changeMailContent('sent')" v-bind:class="{'mail-left-side-element' : true, 'active' : this.currentMail.sent}">
                <img src="" alt="">
                <p>{{ $t('mensajesEnviados') }}</p>
            </div>

            <div class="mail-left-side-element">
                <img src="" alt="">
                <p>{{ $t('spam') }}</p>
            </div>

            <div @click="changeMailContent('draft')" v-bind:class="{'mail-left-side-element' : true, 'active' : this.currentMail.draft}" class="mail-left-side-element">
                <img src="" alt="">
                <p>{{ $t('borradores') }}</p>
                <span v-if="showDraft" class="number">01</span>
            </div>
        </div>
        <div v-if="this.currentMail.draft" class="mail-card mail-right-side-element">
            <draf v-on:send-draft="sendMail"> </draf>
        </div>
        <div v-if="this.currentMail.sent" class="mail-card mail-right-side-element">
            <sent> </sent>
        </div>
        <div id="entryBox" v-if="this.currentMail.entryBox" class="mail-card mail-right-side-element">
            <entry-box v-on:open-browser="openBrowser" v-if="showEntry"> </entry-box>
            <div v-else class="mail-right-side-element-no-message">
                <p>{{ $t('noMessages') }}</p>
            </div>
        </div>
    </div>
</template>

<script>

    import DraggableModal from '@/components/modals/draggable-modal';
    import Draf from './mini-components/draft';
    import Sent from './mini-components/sent';
    import EntryBox from './mini-components/entryBox';


    import { mapGetters } from 'vuex'
    import { mapMutations } from 'vuex'

    export default {
        name: "mail",
        components: {
            DraggableModal,
            Sent,
            Draf,
            EntryBox
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        data() {
            return {
                currentMail: {
                    entryBox: false,
                    sent: false,
                    draft: true,
                },
                showDraft: true,
                showEntry: false,
            }
        },
        created() {
            this.checkPhaseStatus();
        },
        methods: {
            checkPhaseStatus: function () {
                let state = this.getCurrentState();
                this.showEntry = state.phase.id > 3;
                this.showDraft = !this.showEntry;
            },
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            openCloseModal: function () {
                this.open = !this.open;
            },
            changeMailContent: function (box) {
                this.currentMail.entryBox = (box === 'entryBox');
                this.currentMail.draft = (box === 'draft');
                this.currentMail.sent = (box === 'sent');
            },
            sendMail: function () {
                this.showDraft = false;
                this.updateCurrentPhase({id: 4, complete: false, current: true});
                this.updateUserdata();
                setTimeout(() => {
                    this.showEntry = true;
                },3500);
            },
            openBrowser: function () {
                this.$emit('open-browser');
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_mail.scss";

</style>