<template>
    <header>
        <div class="card-header">
            <header-nav-username></header-nav-username>
            <div class="lines-divisor"></div>
            <header-nav-complete-sector></header-nav-complete-sector>
        </div>

        <div class="card-header">
            <header-nav-complete-phases></header-nav-complete-phases>
        </div>

        <div class="card-header">
            <slot name="header-nav-mail"> </slot>
        </div>

        <div class="card-header">
            <header-nav-time></header-nav-time>
        </div>

        <div class="card-header">
            <header-paused-game></header-paused-game>
        </div>
    </header>
</template>

<script>

    import HeaderNavUsername from './mini-components/header-nav-username';
    import HeaderNavCompleteSector from './mini-components/header-nav-complete-sector';
    import HeaderNavCompletePhases from './mini-components/header-nav-complete-phases';
    import HeaderNavTime from './mini-components/header-nav-time';
    import HeaderPausedGame from './mini-components/header-nav-paused';

    export default {
        name: "header-body",
        components: {
            HeaderNavUsername,
            HeaderNavCompleteSector,
            HeaderNavCompletePhases,
            HeaderNavTime,
            HeaderPausedGame
        }
    }

</script>

<style lang="scss">

    @import "@/scss/_variables.scss";

    header {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .card-header {
            display: flex;
            align-items: center;
            background-color: rgba($primary-color, 0.4);
            border: 1px solid rgba(#415997, 0.4);
            border-radius: 7px;
            margin-left: 15px;
            &:first-child {
                margin-left: 0;
            }
        }
        .lines-divisor {
            position: relative;
            margin: 0 25px;
            width: 1px;
            height: 35px;
            &:before,
            &:after {
                content: '';
                opacity: 0.25;
                position: absolute;
                width: 1px;
                background-color: #fff;
                height: calc(50% - 5px);
            }
            &:before {
                top: 0;
            }
            &:after {
               bottom: 0;
            }
        }
        #header-mail {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 7px;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            .mail-icon {
                display: flex;
                align-items: center;
                padding: 15px;
                cursor: pointer;
                min-width: 50px;
                min-height: 50px;
                position: relative;
                img {
                    width: 25px;
                    height: auto;
                    margin-top: 5px;
                    margin-left: 10px;
                }
                span {
                    position: absolute;
                    top: 10px;
                    right: 5px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $red-color;
                    color: #fff;
                }
            }
            &:hover {
                cursor: pointer;
                background-color: $primary-blue-color;
            }
        }
    }
</style>
