<template>
    <div id="header-mail">
        <div class="mail-icon">
            <img src="./../../../../assets/icons/mail-icon.png" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        name: "header-nav-mail",
        data() {
            return {

            }
        },
        methods: {}
    }
</script>