<template>
    <div id="header-time">
        <span v-html="$t('restTime')"></span>
        <div class="lines-divisor"></div>
        <div class="countdown">
            {{ this.getTime().min }} :  {{ this.getTime().sec }}
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: "header-nav-time",
        data() {
            return {}
        },
        created() {
            this.checkTime();
        },
        computed: {
            ...mapGetters(['getTime','getPausedGame'])
        },
        methods: {
            ...mapMutations(['updateTime','updateClosedGame']),
            checkTime: function () {
                let time = this.getTime();
                this.initTime(time.min,time.sec)
            },
            initTime(mm,ss) {
                let interval = setInterval(() => {

                    ss--;

                    let mm_string  = mm;
                    let ss_string  = ss;

                    if(mm.toString().length < 2) mm_string = "0"+mm;
                    if(ss.toString().length < 2) ss_string = "0"+ss;

                    if(!this.getPausedGame()) {
                        this.updateTime({min: mm_string, sec: ss_string});
                    }
                    else clearInterval(interval);


                    if(ss === 0 && mm !== 0) {
                        ss = 59;
                        if(mm !== 0) {
                            mm--;
                        }
                    }

                    if(mm <= 0 && ss <= 0){
                        this.updateClosedGame({closedGame: true});
                        clearInterval(interval);
                    }

                },1000);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #header-time {
        display: flex;
        align-items: center;
        padding: 0 20px;
        span {
            color: #fff;
            text-transform: uppercase;
        }
        .countdown {
            color: $primary-blue-color;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
        }
    }
</style>