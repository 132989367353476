<template>
    <div id="cecoel-app">
        <cecoel-app-password v-if="!openLights" v-on:openLights="openLightsFunction()"></cecoel-app-password>
        <cecoel-app-lights v-else></cecoel-app-lights>
    </div>
</template>

<script>

    import CecoelAppPassword from './mini-components/cecoel-app-password';
    import CecoelAppLights from './mini-components/cecoel-app-lights';

    export default {
        name: "cecoel-app",
        components: {
            CecoelAppPassword,
            CecoelAppLights
        },
        data() {
            return {
                showPass: true,
                openLights: false,
            }
        },
        methods: {
            openLightsFunction: function () {
                this.openLights = true;
            }
        }
    }
</script>

<style scoped>

</style>