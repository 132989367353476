<template>
    <div class="clues-card">
        <div class="clue-box">
            <clue :clueTitle="$t('clueTitle1')" :clueContent="$t('sector2Pista1Fase4')"></clue>
        </div>
        <div class="clue-box">
            <clue :clueTitle="$t('clueTitle2')" :clueContent="$t('sector2Pista2Fase4')"></clue>
        </div>
        <div class="clue-box">
            <clue :clueTitle="$t('clueTitle3')" :clueContent="$t('sector2Pista3Fase4')"></clue>
        </div>
    </div>
</template>

<script>
    import Clue from '@/components/clues/clue.vue';

    export default {
        name: "clues-phase-two",
        components: {
            Clue
        },
    }
</script>