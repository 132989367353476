import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

import VueI18n from 'vue-i18n'
Vue.use(VueI18n);

let es = null;
let en = null;

const getLanguages = async () => {
    try {
        const respEsp = await axios.get('https://conectadosalfuturo.com/endpoints/get_strings.php', {
            params: {
                interactiu: 10, lang: 'esp'
            },
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
        const respEng = await axios.get('https://conectadosalfuturo.com/endpoints/get_strings.php', {
            params: {
                interactiu: 10, lang: 'eng'
            },
            headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
        es = respEsp.data;
        en = respEng.data;
    } catch (err) {
        console.error(err);
    }
};

getLanguages().then( () => {
        const messages = Object.assign({
            es: es,
            en: en
        });

        Vue.config.productionTip = false;
        Vue.config.productionTip = false;

        let i18n = new VueI18n({
            locale: 'es',
            fallbackLocale: 'es',
            messages
        });

        new Vue({
            router,
            store,
            i18n,
            render: function (h) { return h(App) }
        }).$mount('#app');
});


