<template>
    <div id="security-word">
        <cecoel-app-curves v-if="solved"></cecoel-app-curves>
        <div v-else class="security-word-content">
            <p class="title">{{  $t('sector2ClaveDesbloqueo') }}</p>
            <div v-bind:class="{'security-word-box' : true, 'incorrect' : incorrect}">
                <input id="cecoel-app-input" type="text" v-model="writeWord" autocomplete="off">
            </div>
            <div class="button-electric" @click="checkWord">{{ $t('sector2Responder') }}</div>
            <div class="security-info-box">
                <p @click="$emit('openLights')">{{  $t('sector2OlvidarClave') }}</p>
            </div>
        </div>

    </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    import CecoelAppCurves from './cecoel-app-curves';

    export default {
        name: "cecoel-app-password",
        components: {
            CecoelAppCurves
        },
        data() {
            return {
                word: '100',
                writeWord: '',
                solved: false,
                incorrect: false
            }
        },
        created() {
            this.checkPhaseStatus();
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus: function () {
                let state = this.getCurrentState();
                this.solved = state.phase.id > 2;
            },
            checkWord: function () {
                this.writeWord = document.getElementById('cecoel-app-input').value;
                this.solved = this.writeWord.toLowerCase()  === this.word.toLowerCase();
                if(this.solved) {
                    this.updateCurrentPhase({id: 3, complete: false, current: true});
                    this.updateUserdata();
                }
                else {
                    this.incorrect = true;
                    setTimeout(() => {
                        this.incorrect = false;
                    },1500)
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";

    #security-word {
        .security-word-content {
            width: 400px;
            margin: 0 auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            p {
                text-align: center;
                color: #fff;
                &.title {
                    text-transform: uppercase;
                    font-size: 20px;
                    line-height: 22px;
                }
            }
            .security-word-box {
                width: 100%;
                input {
                    padding: 15px;
                    width: 100%;
                    background-color: transparent;
                    border: 1px solid $green-color;
                    border-radius: 10px;
                    box-shadow: none;
                    outline: none;
                    text-align: center;
                    color: $green-color;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 18px;
                    letter-spacing: 10px;
                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                }
                &.incorrect {
                    input {
                        background-color: rgba($red-color, 0.1);
                        border-color: $red-color;
                        color: $red-color;
                    }
                }
            }
            .button-electric {
                margin: 25px 0;
            }
            .security-info-box {
                padding: 20px;
                border-radius: 10px;
                p {
                    font-size: 14px;
                    line-height: 16px;
                    margin: 0;
                    color: $primary-blue-color;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
        .security-solved {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                color: #fff;
                margin: 0;
                text-align: center;
                span {
                    color: $green-color;
                }
                &.title {
                    font-size: 25px;
                    line-height: 30px;
                    color: $green-color;
                    margin-bottom: 20px;
                }
            }
            .button-electric {
                margin-top: 20px;
            }
        }
    }
</style>