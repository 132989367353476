<template>
    <div id="diagrams-folder">
        <div class="file-content">
            <document documentTitle="CDXXXII" documentModalTitle="CDXXXII">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=300 intensityOhm=150 enduranceOhm=2></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="MDCCCLXXXIV" documentModalTitle="MDCCCLXXXIV">
                <template slot="modal-content">
                    <ohm-game voltajeOhm=0 intensityOhm=0 enduranceOhm=0></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="CCXLVIII" documentModalTitle="CCXLVIII">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=525 intensityOhm=105 enduranceOhm=5></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="DCCCII" documentModalTitle="DCCCII">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=800 intensityOhm=80 enduranceOhm=10></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="CMLXXXIX" documentModalTitle="CMLXXXIX">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=525 intensityOhm=105 enduranceOhm=5></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="CCXXXV" documentModalTitle="CCXXXV">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=300 intensityOhm=150 enduranceOhm=2></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="CCCLVI" documentModalTitle="CCCLVI">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=800 intensityOhm=80 enduranceOhm=10></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="CMXI" documentModalTitle="CMXI">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=525 intensityOhm=105 enduranceOhm=5></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="CIX" documentModalTitle="CIX">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=300 intensityOhm=150 enduranceOhm=2></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="DLVI" documentModalTitle="DLVI">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=300 intensityOhm=150 enduranceOhm=2></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="DII" documentModalTitle="DII">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=525 intensityOhm=105 enduranceOhm=5></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="CXXXV" documentModalTitle="CXXXV">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=800 intensityOhm=80 enduranceOhm=10></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="CMXXIX" documentModalTitle="CMXXIX">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=300 intensityOhm=150 enduranceOhm=2></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="LVII" documentModalTitle="LVII">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=300 intensityOhm=150 enduranceOhm=2></ohm-game>
                </template>
            </document>
        </div>
        <div class="file-content">
            <document documentTitle="DXXXVIII" documentModalTitle="DXXXVIII">
                <template slot="modal-content">
                    <ohm-game solvedOhm voltajeOhm=300 intensityOhm=150 enduranceOhm=2></ohm-game>
                </template>
            </document>
        </div>
    </div>
</template>

<script>

    import Document from '@/components/document/document';
    import OhmGame from './../../ohm-game/ohm-game';

    export default {
        name: "diagrams-folder",
        components: {
            Document,
            OhmGame
        },
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #diagrams-folder {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 800px;
        .file-content {
            width: 150px;
            flex-basis: 150px;
            margin-bottom: 25px;
        }

    }
</style>