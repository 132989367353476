<template>
    <div class="clues-card">
        <div class="clue-box">
            <clue :clueTitle="$t('clueTitle1')"
                  :clueContent="$t('sector1Pista1Fase1')"></clue>
        </div>
        <div class="clue-box">
            <clue :clueTitle="$t('clueTitle2')"
                  :clueContent="$t('sector1Pista2Fase1')"></clue>
        </div>
        <div class="clue-box">
            <clue :clueTitle="$t('clueTitle3')"
                  :clueContent="$t('sector1Pista3Fase1')"></clue>
        </div>
    </div>
</template>

<script>
    import Clue from '@/components/clues/clue.vue';

    export default {
        name: "clues-phase-one",
        components: {
            Clue
        },
    }
</script>