<template>
    <div id="app" @click="time = 90">
        <div v-if="!getClosedGame()">
            <screen-save v-if="screensaveEnable" :wrongQr="wrongQr"> </screen-save>
            <div v-else>
                <qwerty></qwerty>
                <div v-if="!getPausedGame()">
                    <sector-one v-if="this.getCurrentState().sector.id === 1"></sector-one>
                    <sector-two v-if="this.getCurrentState().sector.id === 2"></sector-two>
                    <sector-three v-if="this.getCurrentState().sector.id === 3"></sector-three>
                    <sector-four v-if="this.getCurrentState().sector.id === 4"></sector-four>
                    <sector-five v-if="this.getCurrentState().sector.id === 5"></sector-five>
                    <sector-final v-if="this.getCurrentState().sector.id === 6"></sector-final>
                </div>
                <div v-else>
                    <paused-game></paused-game>
                </div>
            </div>
        </div>
        <div v-else>
            <closed-game></closed-game>
        </div>
    </div>
</template>

<script>

    import appSocket from './app_socket';
    import appWeb from './app_web';

    import ScreenSave from '@/components/screensave/screensave.vue';
    import Qwerty from '@/components/qwerty/qwerty.vue';

    import SectorOne from '@/components/sectors/sector-one/sector-one';
    import SectorTwo from '@/components/sectors/sector-two/sector-two';
    import SectorThree from '@/components/sectors/sector-three/sector-three';
    import SectorFour from '@/components/sectors/sector-four/sector-four';
    import SectorFive from '@/components/sectors/sector-five/sector-five';

    import SectorFinal from '@/components/sectors/sector-final/sector-final';
    import PausedGame from '@/components/paused-game/paused-game';
    import ClosedGame from '@/components/closed-game/closed-game';

    export default {
        name: "app",
        extends: appSocket,
        components: {
            Qwerty,
            ScreenSave,
            SectorOne,
            SectorTwo,
            SectorThree,
            SectorFour,
            SectorFive,
            SectorFinal,
            PausedGame,
            ClosedGame
        }
    }

</script>


<style lang="scss">

    @import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;500;700&display=swap');

    @import "@/scss/_variables.scss";

    #app {
        font-family: 'Inconsolata', monospace;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        line-height: 20px;
    }

    body {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        overflow: hidden;
    }

    .sector-content-general {
        height: calc(100vh - 80px);
        margin: 0;
        padding: 40px;
        background: url('./assets/background.jpg') no-repeat center center;
        background-size: cover;
        overflow: hidden;
    }

    .container {
        width: 100%;
        max-width: 1800px;
        margin: 0 auto;
    }

    .button-electric {
        padding: 15px 25px;
        text-transform: uppercase;
        text-align: center;
        border-radius: 10px;
        color: #fff;
        @include gradient(90deg,$list-blue);
        &:hover {
            cursor: pointer;
        }
    }

    * {
        letter-spacing: 1px;
    }

    *::-webkit-scrollbar {
        width: 10px;
    }

    *::-webkit-scrollbar-track {
        border: 1px solid #49519a;
        background-color: #283a8e;
        border-radius: 100px;
    }

    *::-webkit-scrollbar-thumb {
        border-radius: 100px;
        border-left: 0;
        border-right: 0;
        background-color: #97a3dd;
    }

</style>
