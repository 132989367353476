<template>
    <div id="web-browser">

        <whats-app-component v-if="openWhatsapp" v-on:close="checkWhatsapp()"> </whats-app-component>

        <div v-if="!browserSolved" class="web-browser-content">
            <div class="web-browser-content-box">
                <div class="image">

                </div>
                <div class="form">
                    <p>{{ $t('sector2NavegadorTitle') }}</p>
                    <div v-bind:class="{'key-content' : true, 'incorrect' : inputIncorrect}">
                        <input id="key-one" type="text" v-model="keyOne" autocomplete="off">
                        <input id="key-two" type="text" v-model="keyTwo" autocomplete="off">
                        <input id="key-three" type="text" v-model="keyThree" autocomplete="off">
                        <input id="key-four" type="text" v-model="keyFour" autocomplete="off">
                    </div>
                    <div class="button-email access" @click="checkKey()">Acceder</div>
                    <span class="line"></span>
                    <div class="assistence-phone">
                        <p>{{ $t('sector2NavegadorAsistencia') }}</p>
                        <div class="whatsapp" @click="checkWhatsapp()">
                            <span class="whatsapp-icon">
                                <img src="./assets/whatsapp.png" alt="">
                            </span>
                            <p>WhatsApp</p>
                        </div>
                    </div>
                    <p class="problem-message" v-html="$t('sector2NavegadorProblemasAcceder')"> </p>
                </div>
            </div>

        </div>
        <div v-else class="web-browser-solved">
            <sector-complete
                    :sectorText="$t('sector2NavegadorCorrect')"
                    v-on:pass-sector="setNextSector"> </sector-complete>
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'

    import SectorComplete from  '@/components/sector-complete/sector-complete.vue';
    import WhatsAppComponent from './../whatsapp/whatsapp'

    export default {
        name: "web-browser",
        data() {
            return {
                openWhatsapp: false,
                inputIncorrect: false,
                browserSolved: false,
                keyOne: '',
                keyTwo: '',
                keyThree: '',
                keyFour: ''
            }
        },
        components: {
            SectorComplete,
            WhatsAppComponent
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateCurrentSector','updateUserdata']),
            checkKey: function () {

                this.keyOne = document.getElementById('key-one').value;
                this.keyTwo = document.getElementById('key-two').value;
                this.keyThree = document.getElementById('key-three').value;
                this.keyFour = document.getElementById('key-four').value;

                let tempKey = this.keyOne + '' + this.keyTwo + '' + this.keyThree + '' + this.keyFour;
                if(tempKey === '2593') {
                   this.browserSolved = true;
                }
                else {
                    this.inputIncorrect = true;
                    setTimeout(() => {
                        this.inputIncorrect = false;
                    },1500)
                }
            },
            checkWhatsapp: function () {
                this.openWhatsapp = !this.openWhatsapp;
                console.log(this.openWhatsapp);
            },
            setNextSector: function () {
                this.updateCurrentSector({id: 2, complete: true, current: false});
                this.updateCurrentSector({id: 3, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
                this.updateUserdata();
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #web-browser {
        .web-browser-content {
            width: 900px;
            padding: 70px 0;
            background-color: #eaeaea;
            display: flex;
            justify-content: center;
            align-items: center;
            .web-browser-content-box {
                width: 800px;
                display: flex;
                background-color: #fff;
                .image {
                    flex-basis: 50%;
                    width: 50%;
                    background: url('./assets/ree.png') no-repeat center center;
                    background-size: cover;
                }
                .form {
                    flex-basis: calc(50% - 100px);
                    width: calc(50% - 100px);
                    padding: 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    p {
                        color: $primary-color;
                        text-align: center;
                        font-size: 22px;
                        line-height: 24px;
                    }
                    .key-content {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 20px;
                        input {
                            width: 45px;
                            height: 45px;
                            margin-right: 5px;
                            text-align: center;
                            font-size: 22px;
                            line-height: 24px;
                            border: 1px solid #c8c8c8;
                            border-radius: 10px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                        &.incorrect {
                            input {
                                border: 2px solid $red-color;
                            }
                        }
                    }
                    .button-email {
                        padding: 15px 35px;
                        text-align: center;
                        text-transform: uppercase;
                        color: #fff;
                        border-radius: 50px;
                        &.access {
                            background-color: #75ad74;
                        }
                        &.password {
                            background-color: $secondary-blue-color;
                        }
                        &.problem-access {
                            background-color: $primary-blue-color;
                        }
                    }
                    .line {
                        display: block;
                        width: 100px;
                        height: 1px;
                        margin: 25px 0;
                        background-color: #b2b2b2;
                    }
                    .problem-message {
                        font-size: 15px;
                        line-height: 17px;
                    }
                    .assistence-phone {
                        display: flex;
                        align-items: center;
                        text-transform: uppercase;
                        p {
                            font-size: 15px;
                            line-height: 17px;
                            margin: 0;
                            text-align: left;
                        }
                        .whatsapp {
                            margin-left: 15px;
                            display: flex;
                            align-items: center;
                            .whatsapp-icon {
                                z-index: 2;
                                width: 50px;
                                height: auto;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                img  {
                                    width: 100%;
                                    height: auto;
                                }
                            }
                            p {
                                z-index: 1;
                                margin-left: -25px;
                                height: 40px;
                                display: flex;
                                align-items: center;
                                padding-left: 30px;
                                padding-right: 15px;
                                background-color: #eeeeee;
                                border-top-right-radius: 25px;
                                border-bottom-right-radius: 25px;
                            }
                        }
                    }
                }
            }
        }
        .web-browser-solved {

        }
    }
</style>
