<template>
    <div id="draf">
        <div v-if="showDraft" id="draf-content" class="mail-right-side-element-content">
            <div id="from" class="mail-right-side-mail">
                {{ $t('deEmail') }} Dr. Ohm
            </div>
            <div id="delete" class="icon-mail">
                <img src="./../assets/delete.png" alt="">
            </div>
            <div id="send" class="icon-mail" @click="sendMail()">
                <img src="./../assets/send.png" alt="">
            </div>
            <input type="text" id="email-to-sector-two" class="mail-right-side-mail" v-model="email_to" autocomplete="off">
            <div class="mail-right-side-mail">
                {{ $t('asuntoEmail' )}} <span>{{ $t('sector2EmailAsunto')}} </span>
            </div>
            <div class="mail-right-side-content">
                <p v-html="$t('sector2EmailContenido')"> </p>
            </div>
        </div>
        <div v-else class="mail-right-side-element-no-message">
            <p>{{ $t('noMessagesBorradores') }}</p>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    export default {
        name: "draft",
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        data() {
            return {
                showDraft: true,
                email_to: this.$t('paraEmail')
            }
        },
        created() {
            this.checkPhaseStatus();
        },
        methods: {
            checkPhaseStatus: function () {
                let state = this.getCurrentState();
                this.showDraft = state.phase.id === 3;
            },
            sendMail: function () {
                let emailToInput = document.getElementById('email-to-sector-two').value;
                console.log(emailToInput);
                if(String(emailToInput).includes('jefe.de.operaciones@cecoel.com')) {
                    document.getElementById('draf-content').classList.add('sending');
                    this.$emit('send-draft');
                    setTimeout(() => {
                        this.showDraft = false;
                    },1500);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";
    @import "@/scss/_mail.scss";

    #draf-content {
        &.sending {
            opacity: 0;
            transform: scale(0.5);
        }
    }
</style>