<template>
    <div id="final-sector">
        <div class="final-sector-content">
            <name></name>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    import Name from './../games/name-dragdrop/name-dragdrop'

    export default {
        name: "card-desktop-right",
        components: {
            Name
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {

        }
    }
</script>