<template>
    <div id="qwerty">
        <div id="qwerty-button" @click="open = !open" v-bind:class="{'active' : open}">
            <img src="./assets/keyboard.png" alt="">
        </div>
        <div v-if="open" id="qwerty-keyboard" ref="draggableQwerty" @mousedown="dragMouseDown">
            <div class="key">
                <span> < </span>
                <span> > </span>
            </div>
            <div class="key" :id="key.id" v-for="key in keys" @click="setKey(key.name)">{{ key.name }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "qwerty",
        data() {
            return {
                open : false,
                positions: {
                    clientX: undefined,
                    clientY: undefined,
                    movementX: 0,
                    movementY: 0
                },
                keys: [
                    {name: '1', value: '', id: ''},
                    {name: '2', value: '', id: ''},
                    {name: '3', value: '', id: ''},
                    {name: '4', value: '', id: ''},
                    {name: '5', value: '', id: ''},
                    {name: '6', value: '', id: ''},
                    {name: '7', value: '', id: ''},
                    {name: '8', value: '', id: ''},
                    {name: '9', value: '', id: ''},
                    {name: '0', value: '', id: ''},
                    {name: '-', value: '', id: ''},
                    {name: '@', value: '', id: ''},
                    {name: 'Borrar', value: '', id: ''},
                    {name: 'Tab', value: '', id: ''},
                    {name: 'q', value: '', id: ''},
                    {name: 'w', value: '', id: ''},
                    {name: 'e', value: '', id: ''},
                    {name: 'r', value: '', id: ''},
                    {name: 't', value: '', id: ''},
                    {name: 'y', value: '', id: ''},
                    {name: 'u', value: '', id: ''},
                    {name: 'i', value: '', id: ''},
                    {name: 'o', value: '', id: ''},
                    {name: 'p', value: '', id: ''},
                    {name: '[', value: '', id: ''},
                    {name: ']', value: '', id: ''},
                    {name: 'ç', value: '', id: ''},
                    {name: 'Bloq Mayus', value: '', id: ''},
                    {name: 'a', value: '', id: ''},
                    {name: 's', value: '', id: ''},
                    {name: 'd', value: '', id: ''},
                    {name: 'f', value: '', id: ''},
                    {name: 'g', value: '', id: ''},
                    {name: 'h', value: '', id: ''},
                    {name: 'j', value: '', id: ''},
                    {name: 'k', value: '', id: ''},
                    {name: 'l', value: '', id: ''},
                    {name: 'ñ', value: '', id: ''},
                    {name: '{', value: '', id: ''},
                    {name: 'Intro', value: '', id: ''},
                    {name: 'Mayus', value: '', id: ''},
                    {name: 'z', value: '', id: ''},
                    {name: 'x', value: '', id: ''},
                    {name: 'c', value: '', id: ''},
                    {name: 'v', value: '', id: ''},
                    {name: 'b', value: '', id: ''},
                    {name: 'n', value: '', id: ''},
                    {name: 'm', value: '', id: ''},
                    {name: ';', value: '', id: ''},
                    {name: '.', value: '', id: ''},
                    {name: '-', value: '', id: ''}
                ]
            }
        },
        created() {

        },
        methods: {
            setKey: function (value) {

                let element = document.querySelector(":focus");
                if(value === 'Borrar') {
                    element.value = element.value.slice(0, -1);
                }
                else {
                    element.value = element.value + value;
                }

            },
            dragMouseDown: function (event) {

                event.preventDefault();

                // get the mouse cursor position at startup:

                this.positions.clientX = event.clientX;
                this.positions.clientY = event.clientY;

                document.onmousemove = this.elementDrag;
                document.onmouseup = this.closeDragElement;

            },
            elementDrag: function (event) {

                event.preventDefault();

                this.positions.movementX = this.positions.clientX - event.clientX;
                this.positions.movementY = this.positions.clientY - event.clientY;
                this.positions.clientX = event.clientX;
                this.positions.clientY = event.clientY;

                // set the element's new position:

                this.$refs.draggableQwerty.style.top = (this.$refs.draggableQwerty.offsetTop - this.positions.movementY) + 'px';
                this.$refs.draggableQwerty.style.left = (this.$refs.draggableQwerty.offsetLeft - this.positions.movementX) + 'px';
            },
            closeDragElement() {
                document.onmouseup = null;
                document.onmousemove = null;
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #qwerty {
        #qwerty-button {
            opacity: 0.5;
            position: fixed;
            bottom: 15px;
            right: 15px;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background-color: $primary-blue-color;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 60%;
                height: auto;
            }
            &:hover {
                cursor: pointer;
            }
            &.active {
                opacity: 1;
            }
        }
        #qwerty-keyboard {
            position: absolute;
            z-index: 999;
            left: calc(50% - 600px);
            top: calc(100% - 400px);
            padding: 30px;
            border-radius: 25px;
            width: 1062px;
            background-color: $primary-color;
            display: flex;
            flex-wrap: wrap;
            .key {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #5955ba;
                border-radius: 5px;
                text-align: center;
                min-width: 48px;
                padding: 0 10px;
                height: 65px;
                margin: 0 5px 5px 0;
                color: #fff;
                font-size: 18px;
                line-height: 20px;
                font-weight: bold;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                &:hover {
                    cursor: pointer;
                    background-color: $primary-blue-color;
                }
            }
        }
    }

</style>