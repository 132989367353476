import { render, staticRenderFns } from "./solar-panels.vue?vue&type=template&id=40dd098c&scoped=true&"
import script from "./solar-panels.vue?vue&type=script&lang=js&"
export * from "./solar-panels.vue?vue&type=script&lang=js&"
import style0 from "./solar-panels.vue?vue&type=style&index=0&id=40dd098c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40dd098c",
  null
  
)

export default component.exports