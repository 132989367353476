<template>
    <div id="electric-system">
        <div v-if="solved" class="step-solved">
            <sector-complete
                    :sectorText="$t('sector4ProyectoSorianSistemaCorrect')"
                    v-on:pass-sector="setNextSector"></sector-complete>
        </div>
        <div v-else class="electric-system-content">
            <draggable class="scroll-elements-content"
                       group="electricSystem"
                       v-model="naturalElectricElements"
                       @start="isDragging=false"
                       @end="isDragging=false"
                       :move="startDrag">
                <div v-if="natural.showedElement && solvedElectric === natural.solvedElectric" class="scroll-element-box" :id="natural.key" v-for="(natural,index) in naturalElectricElements">
                    <img :src="require(`${natural.image}`)" alt="">
                </div>
            </draggable>
            <div class="map-content">
                <div class="numbers">
                    <div class="number" :id="'number-' + number" v-for="number in numbers">{{ number }}</div>
                </div>

                <draggable class="square" group="electricSystem" :list="positions">
                    <div class="box-square"
                         @mouseover="hoverEnterLetterNumber(position.position)"
                         @mouseleave="hoverLeaveLetterNumber(position.position)"
                         @dragenter="hoverEnterLetterNumber(position.position)"
                         @dragleave="hoverLeaveLetterNumber(position.position)"
                         :id="position.position"
                         v-for="position in positions">
                    </div>
                </draggable>

                <div class="square-floor">

                </div>

                <div class="image-inserts">
                    <img :id="image.position" v-for="image in imageInsert" :src="require(`${image.url}`)" alt="">
                </div>
                <div class="letters">
                    <div class="letter" :id="'letter-' + letter" v-for="letter in letters">{{ letter }}</div>
                </div>
            </div>
            <div class="footer-options">
                <div class="info-box">
                    <img src="./assets/info-circle.png" alt="">
                    <span>{{ $t('sector4ProyectoSorianSistemaFuncionamiento') }}</span>
                </div>
                <div v-if="solvedNatural && !solvedElectric" @click="solvedElectric = true" class="button-electric">
                    {{ $t('sector4ProyectoSorianSistemaContinua') }}
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapMutations} from 'vuex'

    import SectorComplete from '@/components/sector-complete/sector-complete.vue';
    import draggable from 'vuedraggable';

    export default {
        name: "electric-system",
        data() {
            return {
                solved: false,
                solvedNatural: false,
                solvedElectric: false,
                naturalElectricElements: [
                    {image: './assets/mountains.png', key: 'mountains', position: '2A', showedElement: true, solvedElectric: false},
                    {image: './assets/fabric.png', key: 'fabric', position: '5A', showedElement: true, solvedElectric: false},
                    {image: './assets/house.png', key: 'house', position: '7A', showedElement: true, solvedElectric: false},
                    {image: './assets/birds.png', key: 'birds', position: '6D', showedElement: true, solvedElectric: false},
                    {image: './assets/birds.png', key: 'birds-2', position: '4D', showedElement: true, solvedElectric: false},
                    {image: './assets/natural-reserve.png', key: 'natural-reserve', position: '7E', showedElement: true, solvedElectric: false},
                    {image: './assets/torre-distribucion.png', key: 'torreDistribucion', position: '5C', showedElement: true, solvedElectric: true},
                    {image: './assets/torre-pajaros.png', key: 'torrePajaros', position: '5D', showedElement: true, solvedElectric: true},
                    {image: './assets/torre-transporte.png', key: 'torreTransporte', position: '5E', showedElement: true, solvedElectric: true}
                ],
                numbers: [1, 2, 3, 4, 5, 6, 7, 8],
                letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
                positions: [],
                imageInsert: [],

            }
        },
        components: {
            SectorComplete,
            draggable
        },
        created() {
            this.generateMap();
        },
        methods: {
            ...mapMutations(['updateCurrentPhase', 'updateCurrentSector', 'updateUserdata']),
            generateMap: function () {
                this.letters.forEach((letter) => {
                    this.numbers.forEach((number) => {
                        let element = {position: (number + '' + letter)};
                        this.positions.push(element);
                    })
                })
            },
            hoverEnterLetterNumber: function (position) {

                let numberId = 'number-' + position[0];
                let letterId = 'letter-' + position[1];

                document.getElementById(position).classList.add('active');
                document.getElementById(numberId).classList.add('active');
                document.getElementById(letterId).classList.add('active');
            },
            hoverLeaveLetterNumber: function (position) {

                let numberId = 'number-' + position[0];
                let letterId = 'letter-' + position[1];

                document.getElementById(position).classList.remove('active');
                document.getElementById(numberId).classList.remove('active');
                document.getElementById(letterId).classList.remove('active');
            },

            startDrag: function ({relatedContext, draggedContext}) {
                let droppedElement = relatedContext.element;
                let draggedElement = draggedContext.element;

                if (draggedElement.position == droppedElement.position) {

                    let dragPosition = draggedElement.position;
                    let dragImage = draggedElement.image;

                    this.setPuzzlePosition(dragPosition, dragImage, droppedElement.position);
                }
                return false;

            },
            setPuzzlePosition(dragPosition, dragImage, position) {

                this.naturalElectricElements = this.naturalElectricElements.filter(image => image.position != dragPosition);

                let newPosition = position.split("").reverse().join("");
                let newImmage = {position: newPosition, url: dragImage};

                if (!(this.imageInsert.filter(e => e.position == newPosition).length > 0)) {
                    this.imageInsert.push(newImmage);
                }

                if (this.naturalElectricElements.length <= 3) {

                    this.solvedNatural = true;
                    this.updateCurrentPhase({id: 2, complete: false, current: true});
                    this.updateUserdata();

                    if(this.naturalElectricElements.length === 0) {
                        setTimeout(() => {
                            this.solved = true;
                        },2000);
                    }
                }
            },
            setNextSector: function () {
                this.updateCurrentSector({id: 4, complete: true, current: false});
                this.updateCurrentSector({id: 5, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
                this.updateUserdata();
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #electric-system {
        .electric-system-content {
            margin: 50px 0;
            width: 1100px;
            min-width: 1100px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .scroll-elements-content {
                width: 150px;
                border: 1px solid $primary-blue-color;
                border-radius: 5px;
                background-color: $primary-color;
                height: 470px;
                max-height: 470px;
                overflow-y: auto;
                overflow-x: hidden;
                margin-right: 100px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                .scroll-element-box {
                    width: 120px;
                    height: 120px;
                    display: flex;
                    margin-bottom: 10px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 10px;
                    border: 1px solid rgba($primary-blue-color, 0.5);
                    background-color: $secondary-color;
                    img {
                        height: 60%;
                    }
                    &:first-child {
                        margin-top: 10px !important;
                    }
                }
            }
            .map-content {
                position: relative;
                flex-basis: 780px;
                width: 780px;
                min-width: 780px;
                height: 458px;
                min-height: 458px;
                margin-right: 50px;
                .square,
                .square-floor {
                    position: absolute;
                    top: -55px;
                    right: 107px;
                    width: 556px;
                    height: 556px;
                    transform-style: preserve-3d;
                    transform: perspective(190710px) rotateX(55deg) rotateY(0.9deg) rotateZ(-46deg);
                    border-left: 2px solid #3a5313;
                    border-bottom: 2px solid #3a5313;
                    display: flex;
                    flex-wrap: wrap;
                    .box-square {
                        z-index: 4;
                        position: relative;
                        width: calc(69.5px - 1px);
                        height: calc(69.5px - 1px);
                        background-color: transparent;
                        border-right: 1px solid #5c8719;
                        border-bottom: 1px solid #5c8719;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        img {
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                        &:hover, &.active {
                            @include gradient(90deg, $list-green);
                        }
                    }
                }
                .square {
                    z-index: 10;
                }
                .square-floor {
                    z-index: 2;
                    background-color: #75a319;
                }
                .image-inserts {
                    img {
                        position: absolute;
                        &#A2 {
                            z-index: 3;
                            top: 119px;
                            left: 53px;
                        }
                        &#E7 {
                            z-index: 4;
                            top: 89px;
                            right: -6px;
                        }
                        &#A5 {
                            z-index: 8;
                            top: 25px;
                            right: 390px;
                        }
                        &#A7 {
                            z-index: 6;
                            top: -11px;
                            right: 294px;
                        }
                        &#D6 {
                            z-index: 7;
                            top: 102px;
                            right: 292px;
                        }
                        &#D4 {
                            z-index: 8;
                            top: 158px;
                            right: 390px;
                        }
                        &#C5 {
                            z-index: 9;
                            top: 31px;
                            right: 406px;
                            width: 55px;
                        }
                        &#D5 {
                            z-index: 9;
                            top: 80px;
                            right: 360px;
                            width: 55px;
                        }
                        &#E5 {
                            z-index: 9;
                            top: 108px;
                            right: 311px;
                            width: 55px;
                        }
                    }
                }
                .numbers,
                .letters {
                    position: absolute;
                    width: 523px;
                    height: 42px;
                    display: flex;
                    justify-content: space-between;
                    transform-style: preserve-3d;
                    .number,
                    .letter {
                        width: 40px;
                        height: 40px;
                        border-radius: 5px;
                        border: 1px solid $primary-blue-color;
                        color: #fff;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 25px;
                        line-height: 28px;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        &.active {
                            background-color: $primary-blue-color;
                            color: #fff;
                        }
                    }
                }
                .numbers {
                    top: 72px;
                    left: -86px;
                    transform: perspective(190710px) rotateX(55deg) rotateY(0.9deg) rotateZ(-46deg);
                }
                .letters {
                    top: 74px;
                    right: -89px;
                    transform: perspective(190710px) rotateX(55deg) rotateY(0.9deg) rotateZ(-46deg) rotate(90deg);
                }
            }
            .footer-options {
                margin-top: 50px;
                width: 100%;
                flex-basis: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .info-box {
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    border: 1px solid $primary-blue-color;
                    border-radius: 5px;
                    background-color: $primary-color;
                    img {
                        margin-right: 10px;
                    }
                    span {
                        color: #fff;
                        text-transform: uppercase;
                    }
                }
            }
        }

    }

</style>
