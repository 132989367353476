<template>
   <div id="step-four">
       <sector-complete v-if="stepSolved" :sectorText="$t('sector3SistemaVoltaStep1FourCorrect')"  v-on:pass-sector="setNextSector"> </sector-complete>
       <div v-else class="step-four-content">
           <div class="text-box-top">
               <p>{{ $t('sector3SistemaVoltaStep1FourTitle') }}</p>
           </div>
           <div class="key-box">
               <p>{{ $t('sector3SistemaVoltaStep1FourSecuencia') }}</p>
               <div v-bind:class="{'key-box-input' : true, 'incorrect' : errorKey, 'correct' : stepSolved}">
                   <span>{{ keyOne }}</span>
                   <span>{{ keyTwo }}</span>
                   <span>{{ keyThree }}</span>
                   <span>{{ keyFour }}</span>
                   <span>{{ keyFive }}</span>
                   <span>{{ keySix }}</span>
               </div>
           </div>
           <div class="qwerty">
               <div class="numbers">
                   <div class="number" v-bind:style="{backgroundColor: number.color}" @click="clickNumber(number.number)" v-for="number in numbers"></div>
               </div>
               <img src="./assets/stepfour/qwerty.png" alt="">
           </div>
       </div>
   </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    import SectorComplete from  '@/components/sector-complete/sector-complete.vue';

    export default {
        name: "step-four",
        data() {
            return {
                keyOne: '',
                keyTwo: '',
                keyThree: '',
                keyFour: '',
                keyFive: '',
                keySix: '',
                stepSolved: false,
                errorKey: false,
                numbers: [
                    {number: 1, color: '#bdcf73'},
                    {number: 2, color: '#9d6da5'},
                    {number: 3, color: '#db9600'},
                    {number: 4, color: '#801815'},
                    {number: 5, color: '#009bdb'},
                    {number: 6, color: '#009045'},
                    {number: 7, color: '#87683d'},
                    {number: 8, color: '#db9da1'},
                    {number: 9, color: '#d83a49'},
                    {number: 0, color: '#77bae8'}
                ]
            }
        },
        components: {
            SectorComplete
        },
        computed: {
            ...mapGetters(['getCurrentState']),
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateCurrentSector','updateUserdata']),
            clickNumber: function (number) {
                if(this.keyOne === '') this.keyOne = number;
                else if(this.keyTwo === '') this.keyTwo = number;
                else if(this.keyThree === '') this.keyThree = number;
                else if(this.keyFour === '') this.keyFour = number;
                else if(this.keyFive === '') this.keyFive = number;
                else if(this.keySix === '') this.keySix = number;
                if(
                    this.keyOne !== ''
                    && this.keyTwo !== ''
                    && this.keyThree !== ''
                    && this.keyFour !== ''
                    && this.keyFive !== ''
                    && this.keySix !== ''
                ) this.checkSolution();
            },
            checkSolution: function () {
                let finalKey = this.keyOne + '' + this.keyTwo + '' + this.keyThree + '' + this.keyFour + '' + this.keyFive + '' + this.keySix;
                if(finalKey === '185546') {
                    this.stepSolved = true;
                }
                else {
                    this.errorKey = true;
                    setTimeout(() => {
                        this.errorKey = false;
                        this.keyOne = '';
                        this.keyTwo = '';
                        this.keyThree = '';
                        this.keyFour = '';
                        this.keyFive = '';
                        this.keySix = '';
                    },2500)
                }
            },
            setNextSector: function () {
                this.updateCurrentSector({id: 3, complete: true, current: false});
                this.updateCurrentSector({id: 4, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
                this.updateUserdata()
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #step-four {
        margin-top: 20px;
        width: 1000px;
        min-width: 1000px;
        .step-four-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .text-box-top {
                flex-basis: 100%;
                width: 100%;
                height: 92px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                p {
                    color: #fff;
                }
            }
            .key-box {
                padding: 50px 50px;
                border-radius: 15px;
                background-color: #001861;
                margin-bottom: 30px;
                p {
                    text-transform: uppercase;
                    color: #fff;
                    text-align: center;
                    font-size: 18px;
                    line-height: 20px;
                    margin: 0;
                }
                .key-box-input {
                    width: 170px;
                    height: 35px;
                    margin: 30px auto 0 auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span {
                        position: relative;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        width: 21px;
                        height: 35px;
                        font-size: 20px;
                        line-height: 22px;
                        font-weight: bold;
                        color: $primary-blue-color;
                        &:after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            height: 2px;
                            width: 100%;
                            background-color: $primary-blue-color;
                        }
                    }
                    &.incorrect {
                        span {
                            color: $red-color;
                            &:after {
                                background-color: $red-color;
                            }
                        }

                    }
                    &.correct {
                        span {
                            color: $green-color;
                            &:after {
                                background-color: $green-color;
                            }
                        }
                    }
                }
            }
            .qwerty {
                width: 895px;
                min-width: 895px;
                position: relative;
                .numbers {
                    position: absolute;
                    top: 70px;
                    left: 77px;
                    display: flex;
                    .number {
                        width: 47px;
                        height: 47px;
                        border-radius: 5px;
                        margin-right: 7px;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        &:hover {
                            cursor: pointer;
                            transform: scale(0.9);
                        }
                    }
                }
            }
        }
    }
</style>
