<template>
    <draggable-modal v-on:close="openCloseModal()" class="directory-modal">
        <template slot="header">WhatsApp</template>
        <template slot="body">
            <div id="whatsapp">
                <img class="header-whatsapp" src="./assets/header-bar.png" alt="">
                <div class="body-whatsapp">
                    <div v-bind:class ="{'message' : true, 'left': (message.position === 'left'), 'right': (message.position === 'right')}" v-for="message in messages">
                        {{ message.message }}
                    </div>
                </div>
                <div class="footer-whatsapp">
                    <input id="whatsapp-input" v-model="message" type="text" autocomplete="off">
                    <div class="button-whatsapp" @click="sendMessage()">
                        <img src="./assets/whatsapp-send.png" alt="">
                    </div>
                    <img src="./assets/footer-bar.png" alt="">
                </div>
            </div>
        </template>
    </draggable-modal>
</template>

<script>

    import DraggableModal from '@/components/modals/draggable-modal';

    export default {
        name: "whatsapp",
        components: {
            DraggableModal
        },
        data() {
            return {
                message: '',
                messages: [
                    {message: this.$i18n.t('sector2WhatsappMessage'), position: 'left'}
                ]
            }
        },
        methods: {
            openCloseModal: function () {
                this.$emit('close');
            },
            sendMessage: function () {

                let message_temp = document.getElementById('whatsapp-input').value;
                this.messages.push({message: message_temp, position: 'right'});

                this.message = '';


                setTimeout(() => {
                    if(message_temp == 84723645738) {
                        this.messages.push({message: this.$i18n.t('sector2WhatsappMessageCorrect'), position: 'left'});
                    }
                    else {
                        this.messages.push({message: this.$i18n.t('sector2WhatsappMessageIncorrect'), position: 'left'});
                    }
                },1500)

            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #whatsapp {
        width: 800px;
        min-width: 800px;
        .header-whatsapp {
            width: 100%;
            height: auto;
            margin-bottom: -5px;
        }
        .body-whatsapp {
            height: 400px;
            width: calc(100% - 160px);
            background: url('./assets/whatsapp-background.png') no-repeat center center;
            background-size: cover;
            overflow-y: auto;
            padding: 40px 80px;
            .message {
                padding: 20px;
                max-width: 400px;
                margin-bottom: 40px;
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    width: 0;
                    height: 0;
                    border-top: 0px solid transparent;
                    border-bottom: 17px solid transparent;
                }
                &.left {
                    background-color: #fff;
                    margin-right: auto;
                    &::after {
                        left: -26px;
                        border-right: 28px solid #fff;
                    }
                }
                &.right {
                    background-color: #dcf8c6;
                    margin-left: auto;
                    &::after {
                        right: -26px;
                        border-left: 28px solid #dcf8c6;
                    }
                }
            }
        }
        .footer-whatsapp {
            width: 100%;
            height: auto;
            position: relative;
            margin-bottom: -5px;
            input {
                position: absolute;
                top: 7px;
                left: 120px;
                height: 25px;
                width: 520px;
                background-color: #fff;
                border: none;
                outline: none;
                padding: 10px 25px;
                border-radius: 60px;
            }
            .button-whatsapp {
                position: absolute;
                top: 9px;
                right: 50px;
                width: 40px;
                height: 40px;
                background-color: #676767;
                border-radius: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 50%;
                    height: auto;
                }
            }
            img {
                width: 100%;
                height: auto;
            }
        }
    }
</style>