<template>
    <div id="active-button">
        <div v-if="!this.enabled" class="activate-button disabled">
            <p>{{ $t('activateButton') }}</p>
            <div class="circle"><span></span></div>
        </div>
        <div v-else @click="$emit('pass')" class="activate-button enabled">
            <p>{{ $t('activateButton') }}</p>
            <div class="circle"><span></span></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "activate-button",
        props: {enabled : Boolean},
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #active-button {
        .activate-button {
            width: 130px;
            height: 130px;
            border-radius: 15px;
            background-color: #17215d;
            border: 1px solid transparent;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            p {
                margin: 0 0 15px 0;
                color: #fff;
                text-transform: uppercase;
            }
            .circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                height: 40px;
                border-radius: 100px;
                background-color: $primary-blue-color;
                span {
                    display: block;
                    width: 25px;
                    height: 25px;
                    border-radius: 100px;
                    border: 3px solid #fff;
                }
            }
            &.disabled {
                opacity: 0.5;
            }
            &.enabled {
                opacity: 1;
                &:hover {
                    border-color: $primary-blue-color;
                }
            }
        }
    }
</style>