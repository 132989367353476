import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'

import axios from 'axios'

const instance = axios.create({
    withCredentials: false
});

export default {
    data() {
        return {
            wrongQr: false,
            screensaveEnable: false,
            time: 160
        }
    },
    computed: {
        ...mapGetters(['getCurrentState','getPausedGame','getClosedGame'])
    },
    created() {

        const qrCode = this.getCookie('qrCode');
        if(!qrCode) {
            window.location.href = "https://conectadosalfuturo.com/registro/?step=expo&language=esp";
        }

        this.checkUrlSocket();
        //this.reload();

        /* Disable Text Select */
        document.onselectstart = new Function ("return false");
        if (window.sidebar) {
            document.onmousedown = this.disableSelect();
            document.onclick = this.reEnable();
        }

    },
    methods: {
        ...mapMutations(['updateCurrentPhase','updateUsername','updateQRCode','updateCurrentSector','updateGeneralState','updateIsSocket']),
        getCookie: function (name) {
            // Split cookie string and get all individual name=value pairs in an array
            var cookieArr = document.cookie.split(";");

            // Loop through the array elements
            for(var i = 0; i < cookieArr.length; i++) {
                var cookiePair = cookieArr[i].split("=");

                /* Removing whitespace at the beginning of the cookie name
                and compare it with the given string */
                if(name == cookiePair[0].trim()) {
                    // Decode the cookie value and return
                    return decodeURIComponent(cookiePair[1]);
                }
            }
            // Return null if not found
            return null;
        },
        checkUrlSocket: function () {

            let username = this.getCookie('nameExpo');
            this.updateUsername({username : username});
            this.updateIsSocket({isSocket: false});

            let url = window.location.href;

            if(url.includes("eng")) {
                this.$i18n.locale = 'en'
            }

            if(url.includes("sector1")) {
                this.updateCurrentSector({id: 1, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
            }
            else if(url.includes("sector2")) {
                this.updateCurrentSector({id: 2, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
            }
            else if(url.includes("sector3")) {
                this.updateCurrentSector({id: 3, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
            }
            else if(url.includes("sector4")) {
                this.updateCurrentSector({id: 4, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
            }
            else if(url.includes("sector5")) {
                this.updateCurrentSector({id: 5, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
            }
            else {
                this.updateCurrentSector({id: 1, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
            }
        },
        reload: function () {
            setTimeout(() => {
                if(this.time <= 0) window.location.reload();
                else this.time--;
                this.reload();
            }, 1000);
        }
    }
}