<template>
    <div id="sector-complete">
        <img class="top-image" src="./assets/glifo1.png" alt="">
        <div class="sector-complete-box">
            <div class="electra-avatar">
                <img src="./assets/avatar.png" alt="">
            </div>
            <p class="title">{{ $t('sectorCompleteCongrats') }}</p>
            <p class="subtitle">{{ $t('sectorCompleteCongratsSubtitle') }}</p>
            <div class="sector-complete-box-text">
                <p>{{ this.sectorText }}</p>
            </div>
            <div v-if="showButton" class="button-electric" @click="$emit('pass-sector')">{{ $t('sectorCompleteCongratsButton') }}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "sector-complete",
        props: {
            sectorText : String,
            sector: Number
        },
        data() {
            return {
                showButton: true
            }
        },
        created() {
            this.checkUrl();
        },
        methods: {
            checkUrl: function () {
                let url = window.location.href;
                this.showButton = !url.includes("sector") || process.env.VUE_APP_LOCATION_MODE === 'single-location';
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #sector-complete {
        position: fixed;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: url('./assets/background.png') no-repeat center center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .top-image {
            width: 200px;
            height: auto;
        }
        .sector-complete-box {
            width: 550px;
            padding: 30px 0;
            border-radius: 15px;
            background-color: rgba($primary-color,0.5);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .electra-avatar {
                margin: 0 auto 30px auto;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                animation: voice 1.5s infinite linear;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            p {
                margin: 0;
            }
            .title {
                color: #fff;
                font-size: 28px;
                line-height: 30px;
                margin-bottom: 15px;
            }
            .subtitle {
                font-size: 18px;
                line-height: 20px;
                color: $primary-blue-color;
            }
            .sector-complete-box-text {
                display: flex;
                justify-content: center;
                p {
                    width: 90%;
                    text-align: center;
                    color: #fff;
                    margin: 20px 0 40px 0;
                }
            }
        }
    }

    @keyframes voice {
        0% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($green-color,0.1);
            box-shadow: 0px 1px 30px -4px rgba($green-color,0.1);
        }
        50% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($green-color,1);
            box-shadow: 0px 1px 30px -4px rgba($green-color,0.9);
        }
        50% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($green-color,1);
            box-shadow: 0px 1px 30px -4px rgba($green-color,0.9);
        }
        100% {
            -webkit-box-shadow: 0px 1px 30px -4px rgba($primary-blue-color,0.01);
            box-shadow: 0px 1px 30px -4px rgba($green-color,0.01);
        }
    }

</style>
