<template>
    <div class="directories">
        <div class="directory-box">
            <directory :directoryTitle = "$t('sector4ProyectoSorian')"
                       directoryImage = "folder.png"
                       :directoryModalTitle = "$t('sector4ProyectoSorian')"
            >
                <template slot="modal-content">
                    <sorian-projects></sorian-projects>
                </template>
            </directory>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    import Card from '@/components/cards/card.vue';
    import Directory from '@/components/directory/directory.vue';

    import SorianProjects from './../games/sorian-project/sorian-project'

    export default {
        name: "card-desktop-right",
        components: {
            Card,
            Directory,
            SorianProjects
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {
            isBlockedDirectory(idDirectory) {


            }
        }
    }
</script>