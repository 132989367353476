<template>
    <div id="sector-one">
        <intro-sector v-if="introSector"
                      @start-sector="startSector"
                      sector=5>
        </intro-sector>
        <div v-else class="sector-content-general">
            <div class="container">
                <header-body>
                    <template slot="header-nav-mail">
                        <header-mail-one></header-mail-one>
                    </template>
                </header-body>
                <desktop-body>
                    <template slot="body-left-desktop">
                        <desktop-left-one></desktop-left-one>
                    </template>
                    <template slot="body-right-desktop">
                        <desktop-right-one></desktop-right-one>
                    </template>
                </desktop-body>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    import IntroSector from  '@/components/intro-sector/intro-sector.vue';
    import HeaderBody from '@/components/header/header-body.vue';
    import DesktopBody from '@/components/desktop/desktop.vue';

    import HeaderMailOne from './header-mail/header-nav-mail-one';
    import DesktopLeftOne from './desktop/desktop-left-one.vue';
    import DesktopRightOne from './desktop/desktop-right-one';

    export default {
        name: "sector-five",
        components: {
            IntroSector,
            HeaderBody,
            DesktopBody,
            HeaderMailOne,
            DesktopLeftOne,
            DesktopRightOne
        },
        data() {
            return {
                introSector: true
            }
        },
        created() {
            if(!this.getIsSocket() || process.env.VUE_APP_LOCATION_MODE === 'single-location') {
                this.introSector =  false;
            }
        },
        computed: {
            ...mapGetters(['getIsSocket'])
        },
        methods: {
            startSector: function () {
                this.introSector = false;
            }
        }
    }
</script>
