<template>
    <div id="header-mail">
        <div class="mail-icon" @click="openCloseModal()">
            <img src="./../../../../assets/icons/mail-icon.png" alt="">
            <span v-if="showMessage">1</span>
        </div>

        <draggable-modal v-on:close="openCloseModal()" id="mail-modal" v-if="this.open">
            <template slot="header">
                <div class="mail-modal-header">
                    Electra<span>Mail</span>  2.0
                </div>
            </template>
            <template slot="body">
                <mail-one v-on:open-browser="openModalBrowser()"></mail-one>
            </template>
        </draggable-modal>

        <draggable-modal v-on:close="openModalBrowser()" id="game-mail-modal" v-if="this.openBrowser">
            <template slot="header">
                <div class="mail-modal-header">
                    {{ $t('sector2NavegadorWeb') }}
                </div>
            </template>
            <template slot="body">
                <web-browser></web-browser>
            </template>
        </draggable-modal>
    </div>
</template>

<script>

    import DraggableModal from '@/components/modals/draggable-modal';
    import MailOne from './mail-one';

    import WebBrowser from './../games/web-browser/web-browser'

    export default {
        name: "header-nav-mail",
        components: {
            DraggableModal,
            MailOne,
            WebBrowser
        },
        data() {
            return {
                showMessage: false,
                open: false,
                openBrowser: false
            }
        },
        methods: {
            openCloseModal: function () {
                this.open = !this.open;
            },
            openModalBrowser: function () {
                this.openCloseModal();
                this.openBrowser = !this.openBrowser;
            }
        }
    }
</script>