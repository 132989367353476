<template>
    <div class="directories">
        <div class="directory-box">
            <directory :directoryTitle = "$t('sector3SistemaVolta')"
                       directoryImage = "volta-system.png"
                       :directoryModalTitle = "$t('sector3SistemaVolta')"
            >
                <template slot="modal-content">
                    <steps></steps>
                </template>
            </directory>
        </div>
        <div class="directory-box">
            <directory :directoryTitle = "$t('sector3PanelesSolares')"
                       directoryImage = "folder.png"
                       :directoryModalTitle = "$t('sector3PanelesSolares')"
            >
                <template slot="modal-content">
                    <solar-panels></solar-panels>
                </template>
            </directory>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    import Card from '@/components/cards/card.vue';
    import Directory from '@/components/directory/directory.vue';

    import Steps from './../games/steps/steps'
    import SolarPanels from './../games/solar-panels/solar-panels'

    export default {
        name: "card-desktop-right",
        components: {
            Card,
            Directory,
            Steps,
            SolarPanels
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {
            isBlockedDirectory(idDirectory) {
            }
        }
    }
</script>