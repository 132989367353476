<template>
    <div id="closed-game">
        <div class="modal-intro-general">
            <img src="./assets/electra-red.png" alt="">
            <p class="intro-text">GAME OVER</p>
            <img class="cables-red" src="./assets/cables-red.png" alt="">
            <div v-html="$t('gameOverText')"></div>
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: "intro-sector",
        data() {
            return {
                interval : null
            }
        },
        computed: {
            ...mapGetters(['getUsername']),
        },
        created() {
            this.initTime(0,15);
        },
        methods: {
            ...mapMutations(['updatePausedGame']),
            returnGame: function () {
                location.reload();
            },
            initTime(mm,ss) {
                this.interval = setInterval(() => {
                    if(mm ===-1){
                        clearInterval(this.interval);
                        location.reload();
                    }
                    ss--;
                    if(ss === 0) {
                        ss = 59;
                        mm--;
                        if(mm === 0)
                        {
                            mm = 59;
                        }
                    }
                },1000);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #closed-game {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('./../../assets/background.jpg') no-repeat center center;
        background-size: cover;
        text-align: center;
        .button-electric {
            display: inline-block;
            margin-top: 40px;
        }
        .modal-intro-general {
            width: 600px;
            padding: 25px 0 50px 0;
            height: auto;
            background-color: rgba($primary-color,0.8);
            border-radius: 25px;
            .cables-red {
                width: 100%;
                height: auto;
            }
            .intro-text {
                font-size: 35px;
                line-height: 37px;
                color: $red-color;
                margin-top: 15px;
                margin-bottom: 15px;
            }
            p {
                font-weight: bold;
                text-align: center;
                width: 80%;
                margin-left: 10%;
                color: #fff;
            }
        }
    }
</style>