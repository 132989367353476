<template>
    <div id="principal">
        <div class="first-message" v-if="this.getCurrentState().phase.id === 1">
            <p v-html="$t('sector3SistemaVoltaStep1PrincipalError')"> </p>
        </div>
        <div v-else class="table-columns">
            <div class="table-column" style="width: 100%; flex-basis: 100%; margin-bottom: 15px">
                <div class="table-column-header" style="margin: 10px 0">
                    <img src="./../assets/info-circle.png" alt="">
                    {{ $t('sector3SistemaVoltaStep1PrincipalTable1') }}
                </div>
            </div>
            <div v-if="this.getCurrentState().phase.id > 1" class="table-column">
                <div class="table-column-header">
                    <img src="./../assets/info-circle.png" alt="">
                    {{ $t('sector3SistemaVoltaStep1PrincipalTable2') }}

                </div>
                <div class="table-column-content">
                    <div class="column">
                        <div class="row row-header">
                            {{ $t('sector3SistemaVoltaStep1PrincipalTable2Title1') }}
                        </div>
                        <div class="row row-body">A</div>
                        <div class="row row-body">B</div>
                        <div class="row row-body">^</div>
                        <div class="row row-body">D</div>
                        <div class="row row-body">**</div>
                        <div class="row row-body">F</div>
                        <div class="row row-body">||</div>
                        <div class="row row-body">H</div>
                    </div>
                    <div class="column">
                        <div class="row row-header">
                            {{ $t('sector3SistemaVoltaStep1PrincipalTable2Title2') }}
                        </div>
                        <div class="row row-body">300</div>
                        <div class="row row-body">150</div>
                        <div class="row row-body">800</div>
                        <div class="row row-body">450</div>
                        <div class="row row-body">120</div>
                        <div class="row row-body">200</div>
                        <div class="row row-body">450</div>
                        <div class="row row-body">300</div>
                    </div>
                </div>
            </div>
            <div v-if="this.getCurrentState().phase.id > 2" class="table-column">
                <div class="table-column-header">
                    <img src="./../assets/info-circle.png" alt="">
                    {{ $t('sector3SistemaVoltaStep1PrincipalTable3') }}
                </div>
                <div class="table-column-content">
                    <div class="column">
                        <div class="row row-header">
                            {{ $t('sector3SistemaVoltaStep1PrincipalTable3Title1') }}
                        </div>
                        <div class="row row-body">N1</div>
                        <div class="row row-body">N2</div>
                        <div class="row row-body">NE</div>
                        <div class="row row-body">E</div>
                        <div class="row row-body">S1</div>
                        <div class="row row-body">S2</div>
                        <div class="row row-body">SO</div>
                    </div>
                    <div class="column">
                        <div class="row row-header">
                            {{ $t('sector3SistemaVoltaStep1PrincipalTable3Title2') }}
                        </div>
                        <div class="row row-body">45</div>
                        <div class="row row-body">130</div>
                        <div class="row row-body">75</div>
                        <div class="row row-body">240</div>
                        <div class="row row-body">100</div>
                        <div class="row row-body">80</div>
                        <div class="row row-body">45</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    export default {
        name: "step-principal",
        computed: {
            ...mapGetters(['getCurrentState'])
        },
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #principal {
        padding: 30px;
        .first-message {
            p {
                color: #fff;
                font-weight: bold;
                &:first-child {
                    font-size: 18px;
                    line-height: 20px;
                    color: $primary-blue-color;
                }
            }
        }
        .table-columns {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .table-column {
                flex-basis: calc(50% - 20px - 30px);
                width: calc(50% - 20px);
                padding: 15px;
                border-radius: 15px;
                border: 1px solid $primary-blue-color;
                * {
                    color: #fff;
                }
                .table-column-header {
                    display: flex;
                    align-items: center;
                    font-size: 22px;
                    line-height: 24px;
                    margin: 10px 0 25px 0;
                    img {
                        margin-right: 10px;
                    }
                }
                .table-column-content {
                    display: flex;
                    justify-content: space-between;
                    .column {
                        flex-basis: calc(50% - 7.5px);
                        width: calc(50% - 7.5px);
                        .row {
                            padding: 10px 0;
                            text-align: center;
                            &.row-header {
                                background-color: #0f1a52;
                                margin-bottom: 10px;
                                border-radius: 10px;
                            }
                            &.row-body {
                                &:nth-child(even) {
                                    background-color: #0f1a52;
                                }
                                &:nth-child(odd) {
                                    background-color: #060f3e;
                                }
                            }
                        }
                    }

                }
            }
        }
    }
</style>