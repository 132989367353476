<template>
    <div id="security-word" class="directories">
        <div v-if="!solved" class="security-word-content">
            <p class="title">{{ $t('sostenibilidadTitle') }}</p>
            <p>{{ $t('sostenibilidadTitle2') }}</p>
            <div v-bind:class="{'security-word-box' : true, 'incorrect': incorrect}">
                <input id="sostenibility-input" type="text" v-model="writeWord" autocomplete="off">
            </div>
            <p v-if="incorrect" class="error-message">{{ $t('sostenibilidadError') }}</p>
            <div class="button-electric" @click="checkWord">{{ $t('sostenibilidadBoton') }}</div>
            <div class="security-info-box">
                <p>{{ $t('sostenibilidadPregunta') }}</p>
            </div>
        </div>
        <div v-else class="security-solved">
            <img :src="require(`./assets/electra-success.png`)" alt="">
            <p class="title">{{ $t('sostenibilidadCorrecionTitulo') }}</p>
            <p>{{ $t('sostenibilidadCorrecionContenido') }}</p>
            <div class="button-electric" @click="setNextPhase()">{{ $t('sostenibilidadBoton2') }}</div>
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: "security-word",
        data() {
            return {
                word: 'elektron',
                writeWord: '',
                incorrect: false,
                solved: false,
            }
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        created() {
            this.checkPhaseStatus();
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus: function () {
                let state = this.getCurrentState();
                this.solved = state.sector.id === 1 && state.phase.id > 2;
            },

            checkWord: function () {
                this.writeWord = document.getElementById('sostenibility-input').value;
                this.solved = this.writeWord.toLowerCase()  === this.word.toLowerCase();
                if(!this.solved) {
                    this.incorrect = true;
                    setTimeout(() => {
                        this.incorrect = false;
                        this.writeWord = '';
                    },2500)
                }
            },

            setNextPhase: function () {
                this.updateCurrentPhase({id: 3, complete: false, current: true});
                this.updateUserdata();
            }
        }

    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #security-word {
        width: 400px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .security-word-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                text-align: center;
                color: #fff;
                &.title {
                    text-transform: uppercase;
                    font-size: 20px;
                    line-height: 22px;
                }
            }
            .security-word-box {
                width: 100%;
                input {
                    padding: 15px;
                    width: 100%;
                    background-color: transparent;
                    border: 1px solid $green-color;
                    border-radius: 10px;
                    box-shadow: none;
                    outline: none;
                    text-align: center;
                    color: $green-color;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 18px;
                    letter-spacing: 10px;
                }
                &.incorrect {
                    input {
                        background-color: rgba($red-color, 0.1);
                        border-color: $red-color;
                        color: $red-color;
                    }
                }
            }
            .error-message {
                color: $red-color;
            }
            .button-electric {
                margin: 25px 0;
            }
            .security-info-box {
                padding: 20px;
                border-radius: 10px;
                background-color: $primary-color;
                border: 1px solid rgba($primary-blue-color,0.3);
                p {
                    font-size: 14px;
                    line-height: 16px;
                    margin: 0;
                }
            }
        }
        .security-solved {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                color: #fff;
                margin: 0;
                text-align: center;
                span {
                    color: $green-color;
                }
                &.title {
                    font-size: 25px;
                    line-height: 30px;
                    color: $green-color;
                    margin-bottom: 20px;
                }
            }
            .button-electric {
                margin-top: 20px;
            }
        }
    }
</style>