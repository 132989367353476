<template>
    <div id="header-nav-username">
        <img src="./../../../assets/header-nav/avatar.jpg" alt="">
        <span>{{ getUsername() }}</span>
    </div>
</template>

<script>

    import { mapGetters } from 'vuex'

    export default {
        name: "header-nav-username",
        computed: {
            ...mapGetters(['getUsername'])
        }
    }
</script>

<style lang="scss" scoped>
    #header-nav-username {
        display: flex;
        align-items: center;
        padding: 15px;
        border-radius: 7px;
        max-width: 150px;
        img {
            width: 50px;
            height: 50px;
            border-radius: 5px;
            margin-right: 15px;
        }
        span {
            color: #fff;
            text-transform: uppercase;
            word-break: break-all;
        }
    }
</style>