<template>
    <div id="entryBox">
        <div class="mail-right-side-element-content">
            <div id="from" class="mail-right-side-mail">
                {{ $t('deEmail') }} jefe.de.operaciones@cecoel.com
            </div>
            <div id="delete" class="icon-mail">
                <img src="./../assets/delete.png" alt="">
            </div>
            <div id="send" class="icon-mail">
                <img src="./../assets/send.png" alt="">
            </div>
            <div class="mail-right-side-mail">
                {{ $t('paraEmail') }} Dra. Volta
            </div>
            <div class="mail-right-side-mail">
                {{ $t('asuntoEmail') }} <span> {{ $t('sector2EmailEntradaAsunto') }}</span>
            </div>
            <div class="mail-right-side-content">
                <p v-html="$t('sector2EmailEntradaContenido')"></p>
                <p><span class="link" @click="$emit('open-browser')">{{ $t('sector2EmailEntradaLinks') }}</span></p>
                <p v-html="$t('sector2EmailEntradaCierre')"> </p>
                <img class="signature" src="./../assets/signature.png" alt="">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "entry-box"
    }
</script>

<style lang="scss" scoped>
    @import "@/scss/_variables.scss";
    @import "@/scss/_mail.scss";

    #entryBox {
        .link {
            color: $primary-blue-color;
            &:hover {
                text-decoration: underline;
            }
        }
        .signature {
            width: 350px;
            height: auto;
        }
    }
</style>