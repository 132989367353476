<template>
    <div id="morse-code">
        <p>.-- .. -- ... .... ..- .-. ... -</p>
    </div>
</template>

<script>
    export default {
        name: "morse-code"
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #morse-code {
        p {
            color: $primary-blue-color;
            font-size: 25px;
            line-height: 30px;
            font-weight: bold;
            text-align: center;
        }
    }
</style>