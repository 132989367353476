<template>
    <div id="van-de-graff">
        <div v-if="!puzzleResolved" class="puzzle-container">
            <draggable group="vandegraff" v-model="images" @start="isDragging=false" @end="isDragging=false" :move="startDrag" @drag.prevent @dragover.prevent>
                <img :key="'image' + index" :src="require(`${image.url}`)" :id="image.key"  v-for="(image,index) in images" >
            </draggable>
        </div>
        <div v-else class="puzzle-resolved">
            <img :src="require(`./assets/grifo_1.png`)" alt="">
            <p class="title">{{ $t('generadorVanderGraffResuelto1') }}</p>
            <p>{{ $t('generadorVanderGraffResuelto2') }}</p>
        </div>
        <div class="puzzle-result">
            <draggable group="vandegraff" :options="{disabled: puzzleResolved}" class="puzzle-result-image" :list="results">
                <div :id="'result-' + result.result" :key="'result-' + result.result" v-for="result in results" class="puzzle-result-drop-zone">
                    <img v-if="result.url" :src="require(`${result.url}`)" alt="">
                </div>
            </draggable>
            <div v-bind:class="{'puzzle-result-letter' : true, 'puzzle-result-letter-finish' : puzzleResolved}" class="puzzle-result-letter">
                <span v-if="result.letter !== ' '" v-bind:class="{'has-letter' : result.letter !== ''}" v-for="result in results">{{ result.letter }}</span>
            </div>
        </div>
    </div>
</template>

<script>

    import draggable from 'vuedraggable'

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    export default {
        name: "van-de-graff-generator",
        data() {
            return {
                images: [
                    {url: './assets/puzzle-van-de-graff-1.png', key: 'puzzle-1', order: 1, result: 1, letter: 'E'},
                    {url: './assets/puzzle-van-de-graff-2.png', key: 'puzzle-2', order: 2, result: 2, letter: 'L'},
                    {url: './assets/puzzle-van-de-graff-3.png', key: 'puzzle-3', order: 3, result: 3, letter: 'E'},
                    {url: './assets/puzzle-van-de-graff-4.png', key: 'puzzle-4', order: 4, result: 4, letter: 'K'},
                    {url: './assets/puzzle-van-de-graff-5.png', key: 'puzzle-5', order: 5, result: 5, letter: 'T'},
                    {url: './assets/puzzle-van-de-graff-6.png', key: 'puzzle-6', order: 6, result: 6, letter: 'R'},
                    {url: './assets/puzzle-van-de-graff-7.png', key: 'puzzle-7', order: 7, result: 7, letter: 'O'},
                    {url: './assets/puzzle-van-de-graff-8.png', key: 'puzzle-8', order: 8, result: 8, letter: 'N'},
                    {url: './assets/puzzle-van-de-graff-9.png', key: 'puzzle-9', order: 9, result: 9, letter: ' '}
                ],
                results: [
                    {result: 1, url: null, order: null, letter: ''},
                    {result: 2, url: null, order: null, letter: ''},
                    {result: 3, url: null, order: null, letter: ''},
                    {result: 4, url: null, order: null, letter: ''},
                    {result: 5, url: null, order: null, letter: ''},
                    {result: 6, url: null, order: null, letter: ''},
                    {result: 7, url: null, order: null, letter: ''},
                    {result: 8, url: null, order: null, letter: ''},
                    {result: 9, url: null, order: null, letter: ''},
                ],
                puzzleResolved: false
            }
        },
        components: {
            draggable,
        },
        created() {
            this.checkPhaseStatus();
            this.imageReOrder()
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateUserdata']),
            checkPhaseStatus : function() {
                let state = this.getCurrentState();
                let solved = state.sector.id > 1 || state.phase.id > 1;
                if(solved) {
                    this.puzzleResolved = solved;
                    this.solvedPuzzle();
                }
                else this.imageReOrder();
            },
            imageReOrder : function() {
                return this.images;
            },

            solvedPuzzle : function(){
               this.images.forEach((image, index) => {
                   this.results[index].url = image.url;
                   this.results[index].order = image.order;
                   this.results[index].letter = image.letter;
               })
            },
            startDrag : function ({ relatedContext, draggedContext }) {

                let droppedElement = relatedContext.element;
                let draggedElement = draggedContext.element;

                if(draggedElement.order === droppedElement.result) {

                    let dragOrder = draggedElement.order;
                    let dragUrl =  draggedElement.url;
                    let dragLetter =  draggedElement.letter;

                    this.setPuzzlePosition(dragOrder,dragUrl,dragLetter,droppedElement);
                }

                return false;

            },
            setPuzzlePosition(dragOrder,dragUrl,dragLetter,result) {

                this.images = this.images.filter(image => image.order != dragOrder);
                result.order = dragOrder;
                result.url = dragUrl;
                result.letter = dragLetter;

                if(this.images.length === 0) this.checkPuzzle();
            },

            checkPuzzle() {
                this.puzzleResolved = this.results.every(result => parseInt(result.result) === parseInt(result.order));
                if(this.puzzleResolved) {
                    this.updateCurrentPhase({id: 2, complete: false, current: true});
                    this.updateUserdata();
                }
            }

        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #van-de-graff {
        display: flex;
        .puzzle-container,
        .puzzle-result{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
        }
        .puzzle-container {
            width: 550px;
            margin-right: 15px;
            img {
                margin: 10px;
            }
        }
        .puzzle-resolved {
            width: 430px;
            padding: 25px 0;
            border: 1px solid $primary-purple-color;
            border-radius: 10px;
            background-color: rgba(#34439a,0.5);
            margin-right: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img {
                position: relative !important;
                top: 0 !important;
                left: 0 !important;
            }
            p {
                margin-bottom: 0;
                color: #fff;
                &.title {
                    font-size: 25px;
                    line-height: 22px;
                }
            }
        }
        .puzzle-result {
            width: 430px;
            padding: 25px 0;
            border: 1px solid $primary-purple-color;
            border-radius: 10px;
            background-color: rgba(#34439a,0.5);
            margin-left: 15px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .puzzle-result-image {
                width: 315px;
                height: 315px;
                display: flex;
                flex-wrap: wrap;
                border: 1px solid $primary-purple-color;
                .puzzle-result-drop-zone {
                    position: relative;
                    width: 103px;
                    height: 103px;
                    border: 1px solid rgba($primary-purple-color,0.5);
                    img {
                        position: absolute;
                        top: 0;
                    }
                    &:nth-child(1) { img {left: -1px;} }
                    &:nth-child(2) { img {left: -13px}}
                    &:nth-child(3) { img {right: -1px}}
                    &:nth-child(4) { img {left: -1px; top: -41px}}
                    &:nth-child(5) { img {left: -46px; top: -9px}}
                    &:nth-child(6) { img {right: -1px; top: -41px}}
                    &:nth-child(7) { img {left: -1px; top: -8px}}
                    &:nth-child(8) { img {left: -13px; top: -40px}}
                    &:nth-child(9) { img {right: -1px; top: -8px}}
                }
            }
            .puzzle-result-letter {
                width: calc(90% - 30px);
                margin-top: 20px;
                display: flex;
                justify-content: center;
                padding: 15px;
                border-radius: 10px;
                background-color: $primary-color;
                -webkit-transition: 0.3s;
                transition: 0.3s;
                span {
                    position: relative;
                    width: 20px;
                    height: 30px;
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:after {
                        content: '';
                        border-radius: 2px;
                        position: absolute;
                        width: 100%;
                        height: 2px;
                        bottom: 0;
                        background-color: #fff;
                    }
                    &.has-letter {
                        color: $primary-blue-color;
                        -webkit-transition: 0.3s;
                        transition: 0.3s;
                        &:after {
                            background-color: $primary-blue-color;
                            -webkit-transition: 0.3s;
                            transition: 0.3s;
                        }
                    }
                }
                &.puzzle-result-letter-finish {
                    background-color: $primary-blue-color;
                    span {
                        &.has-letter {
                            color: #fff;
                            &:after {
                                background-color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
</style>