<template>
    <div id="security-word">
        <sector-complete v-if="solved" :sectorText="$t('transicionEnergeticaResuelto')" v-on:pass-sector="setNextSector"></sector-complete>
        <div v-else class="security-word-content">
            <p class="title">{{ $t('transicionEnergeticaTitle') }}</p>
            <p>{{ $t('transicionEnergeticaTitle2') }}</p>
            <div v-bind:class="{'security-word-box' : true, 'incorrect': incorrect}">
                <input id="security-word-transition-one" type="text" v-model="writeWordOne" autocomplete="off">
                <span>º</span>
                <input id="security-word-transition-two" type="text" v-model="writeWordTwo" autocomplete="off">
                <span>'</span>
                <input id="security-word-transition-three" type="text" v-model="writeWordThree" autocomplete="off">
                <span>"N</span>
                <input id="security-word-transition-four" type="text" v-model="writeWordFour" autocomplete="off">
                <span>º</span>
                <input id="security-word-transition-five" type="text" v-model="writeWordFive" autocomplete="off">
                <span>'</span>
                <input id="security-word-transition-six" type="text" v-model="writeWordSix" autocomplete="off">
                <span>"E</span>
            </div>
            <div class="button-electric" @click="checkWord">Responder</div>
            <div class="security-info-box">
                <p>{{ $t('transicionEnergeticaPregunta') }}</p>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex'
    import { mapGetters } from 'vuex'

    import SectorComplete from  '@/components/sector-complete/sector-complete.vue';

    export default {
        name: "security-word",
        data() {
            return {
                word: '41241221026',
                writeWordOne: '',
                writeWordTwo: '',
                writeWordThree: '',
                writeWordFour: '',
                writeWordFive: '',
                writeWordSix: '',
                solved: false,
                incorrect: false
            }
        },
        components: {
            SectorComplete
        },
        computed: {
            ...mapGetters(['getCurrentState'])
        },
        methods: {
            ...mapMutations(['updateCurrentPhase','updateCurrentSector','updateUserdata']),

            checkWord: function () {

                this.writeWordOne = document.getElementById('security-word-transition-one').value;
                this.writeWordTwo = document.getElementById('security-word-transition-two').value;
                this.writeWordThree = document.getElementById('security-word-transition-three').value;
                this.writeWordFour = document.getElementById('security-word-transition-four').value;
                this.writeWordFive = document.getElementById('security-word-transition-five').value;
                this.writeWordSix = document.getElementById('security-word-transition-six').value;

                let finalWorlds = this.writeWordOne
                                    +''+this.writeWordTwo
                                    +''+this.writeWordThree
                                    +''+this.writeWordFour
                                    +''+this.writeWordFive
                                    +''+this.writeWordSix;

                this.solved = finalWorlds  === this.word.toLowerCase();
                if(!this.solved) {
                    this.incorrect = true;
                    setTimeout(() => {
                        this.incorrect = false;
                    },1500)
                }
            },

            setNextSector: function () {
                this.updateCurrentSector({id: 1, complete: true, current: false});
                this.updateCurrentSector({id: 2, complete: false, current: true});
                this.updateCurrentPhase({id: 1, complete: false, current: true});
                this.updateUserdata();
            }
        }

    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    #security-word {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        .security-word-content {
            width: 600px;
            min-width: 600px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p {
                text-align: center;
                color: #fff;
                &.title {
                    text-transform: uppercase;
                    font-size: 20px;
                    line-height: 22px;
                }
            }
            .security-word-box {
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                input {
                    padding: 15px 0;
                    width: 45px;
                    background-color: transparent;
                    border: 1px solid $green-color;
                    border-radius: 10px;
                    box-shadow: none;
                    outline: none;
                    text-align: center;
                    color: $green-color;
                    text-transform: uppercase;
                    font-size: 16px;
                    line-height: 18px;
                    letter-spacing: 0px;
                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                }
                span  {
                    display: inline-block;
                    color: #fff;
                    font-size: 25px;
                    line-height: 30px;
                    margin: 0 8px;
                }
                &.incorrect {
                    input {
                        background-color: rgba($red-color, 0.1);
                        border-color: $red-color;
                        color: $red-color;
                    }
                }
            }
            .button-electric {
                margin: 25px 0;
            }
            .security-info-box {
                padding: 20px;
                border-radius: 10px;
                background-color: $primary-color;
                p {
                    font-size: 14px;
                    line-height: 16px;
                    margin: 0;
                }
            }
        }
    }
</style>
