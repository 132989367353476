<template>
    <div ref="draggableModal" class="modal-draggable" v-if="open">
        <div class="header-modal" @pointerdown="dragPointerDown">
            <slot name="header"> </slot>
            <div class="header-modals-buttons">
                <div v-if="!minimize" @click="minimize = true" style="margin-right: 8px" class="header-modal-button"> _ </div>
                <div v-else @click="minimize = false" style="margin-right: 8px" class="header-modal-button">[]</div>
                <div @click="$emit('close')" class="header-modal-button"> X </div>
            </div>
        </div>
        <div v-if="!minimize" class="body-modal">
            <slot name="body"> </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "modal",
        data() {
            return {
                open : true,
                minimize : false,
                positions: {
                    clientX: undefined,
                    clientY: undefined,
                    movementX: 0,
                    movementY: 0
                }
            }
        },
        methods: {
            dragPointerDown : function () {

                event.preventDefault();

                // get the touch position at startup:

                this.positions.clientX = event.clientX;
                this.positions.clientY = event.clientY;

                document.onpointermove = this.elementDrag;
                document.onpointerup = this.closeDragElementPointer;

            },
            elementDrag: function (event) {

                event.preventDefault();

                this.positions.movementX = this.positions.clientX - event.clientX;
                this.positions.movementY = this.positions.clientY - event.clientY;
                this.positions.clientX = event.clientX;
                this.positions.clientY = event.clientY;

                // set the element's new position:

                this.$refs.draggableModal.style.top = (this.$refs.draggableModal.offsetTop - this.positions.movementY) + 'px';
                this.$refs.draggableModal.style.left = (this.$refs.draggableModal.offsetLeft - this.positions.movementX) + 'px';

            },
            closeDragElementPointer (event) {

                event.preventDefault();

                document.onpointermove = null;
                document.onpointerup = null;

            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "@/scss/_variables.scss";

    .modal-draggable {
        min-width: 531px;
        position: fixed;
        touch-action: none;
        z-index: 9999;
        top: 8%;
        left: 20%;
        -webkit-box-shadow: 4px 5px 29px -2px rgba(0,0,0,0.6);
        box-shadow: 4px 5px 29px -2px rgba(0,0,0,0.6);
        .header-modal {
            cursor: move;
            z-index: 10;
            text-transform: uppercase;
            color: #fff;
            padding: 15px 15px 15px 40px;
            @include gradient(90deg,$list-purple);
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            .header-modals-buttons {
                display: flex;
                align-items: center;
                .header-modal-button {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    color: #fff;
                    font-weight: bold;
                    border-radius: 5px;
                    background-color: $primary-color;
                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                    &:hover {
                        background-color: $primary-blue-color;
                    }
                }
            }
        }
        .body-modal {
            width: 100%;
            background-color: rgba($primary-color, 0.9);
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }
    }

</style>